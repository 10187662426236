import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';
import { Btn } from 'styles/button.styles';
import { mobileOnly } from 'styles/mobile.styles';

interface HeaderContainerProps {
  $isTransparent: boolean;
}

export const HeaderContainer = styled.header<HeaderContainerProps>`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 4rem;
  height: var(--height-header);
  width: 100%;
  padding: 0 3.5rem;
  color: ${(props) => (props.$isTransparent ? '#fff' : 'var(--color-text)')};
  background-color: ${(props) =>
    props.$isTransparent ? 'transparent' : '#fff'};
  /* border-bottom: 0.1rem solid rgba(255, 255, 255, 0.5); */
  z-index: 100;

  nav {
    .header__menu {
      display: flex;
      gap: 2.5rem;

      a {
        font-weight: 500;

        &:active {
          opacity: 0.8;
        }

        &.active {
          ${(props) => !props.$isTransparent && 'color: var(--color-primary);'}
          font-weight: 700;
        }
      }
    }

    @media ${breakpoints.mobile} {
      display: none;
    }
  }

  @media ${breakpoints.mobile} {
    justify-content: space-between;
    padding: 0 1.5rem;
  }
`;

export const HeaderLogo = styled(Link)`
  img {
    width: 14rem;

    @media ${breakpoints.mobile} {
      width: 9rem;
    }
  }
`;

export const HeaderMobileNavBtn = styled(Btn)`
  display: none;
  font-size: 2.2rem;

  @media ${breakpoints.mobile} {
    display: block;
  }
`;

export const MobileNavContainer = styled.div`
  ${mobileOnly}

  .mobile-nav__dimmer {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.6rem);
    -webkit-backdrop-filter: blur(0.6rem);
    opacity: 0;
    z-index: 1010;
  }

  .mobile-nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 30rem;
    padding: 2rem;
    background-color: var(--color-bg);
    transform: translateX(100%);
    z-index: 1020;

    .mobile-nav__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      .mobile-nav__logo {
        width: 8rem;
      }
    }

    .mobile-nav__menu {
      padding: 2rem 0;

      li {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        a {
          position: relative;
          display: inline-block;
          padding: 0.5rem 0;

          &.active {
            color: var(--color-primary);
            font-weight: 700;

            &::after {
              content: '';
              position: absolute;
              bottom: 0.2rem;
              left: -0.2rem;
              height: 0.2rem;
              width: calc(100% + 0.4rem);
              background-color: var(--color-primary);
            }
          }
        }
      }
    }
  }
`;

export const MobileNavCloseBtn = styled(Btn)`
  font-size: 2rem;
`;
