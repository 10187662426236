import { Swiper, SwiperSlide } from 'swiper/react';
import ContactBanner from 'components/ContactBanner';
import {
  OverviewContent,
  OverviewCoreValue,
  OverviewLabel,
  OverviewSummary,
} from './styles';
import { Container } from 'styles/container.styles';
import logo from 'assets/img/logo/retailx/logo.png';
import summaryCompany from 'assets/img/company/overview/summary/company.png';
import summaryEstablish from 'assets/img/company/overview/summary/establish.png';
import summaryChief from 'assets/img/company/overview/summary/chief.png';
import summaryLocation from 'assets/img/company/overview/summary/location.png';
import summaryCategory from 'assets/img/company/overview/summary/category.png';
import summaryTel from 'assets/img/company/overview/summary/tel.png';
import summaryFax from 'assets/img/company/overview/summary/fax.png';
import summaryEmail from 'assets/img/company/overview/summary/email.png';
import coreInnovation from 'assets/img/company/overview/coreValue/innovation.png';
import coreInnovationIcon from 'assets/img/company/overview/coreValue/innovation_icon.png';
import coreIdea from 'assets/img/company/overview/coreValue/idea.png';
import coreIdeaIcon from 'assets/img/company/overview/coreValue/idea_icon.png';
import coreCustomer from 'assets/img/company/overview/coreValue/customer.png';
import coreCustomerIcon from 'assets/img/company/overview/coreValue/customer_icon.png';
import coreChallenge from 'assets/img/company/overview/coreValue/challenge.png';
import coreChallengeIcon from 'assets/img/company/overview/coreValue/challenge_icon.png';

const Overview = () => {
  return (
    <>
      <Container>
        <OverviewContent>
          <small>Overview</small>

          <p>
            <b>
              <img src={logo} />는 혁신 기술로 최고의 서비스를 제공합니다.
            </b>
          </p>

          <p>
            리테일엑스는 30년간 축적된 개발 전문가의 노하우가 있는 동네 슈퍼의
            디지털화를 선도하는 e-Commerce, POS, KIOSK 개발 전문 업체입니다.
            혁신 기술과 창의적인 아이디어로 최상의 서비스를 제공하는 기업으로
            당사의 목표는 항상 고객의 만족입니다.{' '}
          </p>
        </OverviewContent>
      </Container>

      <ContactBanner type='green' />

      <Container>
        <OverviewSummary>
          <OverviewLabel>회사개요</OverviewLabel>

          <div className='summary__grid'>
            <div className='summary'>
              <div className='summary__head'>
                <p>회사명</p>

                <img src={summaryCompany} />
              </div>

              <p className='summary__value'>{process.env.REACT_APP_COMPANY}</p>
            </div>

            <div className='summary'>
              <div className='summary__head'>
                <p>설립일</p>

                <img src={summaryEstablish} />
              </div>

              <p className='summary__value'>2019.12.17</p>
            </div>

            <div className='summary'>
              <div className='summary__head'>
                <p>CEO</p>

                <img src={summaryChief} />
              </div>

              <p className='summary__value'>{process.env.REACT_APP_CHIEF}</p>
            </div>

            <div className='summary'>
              <div className='summary__head'>
                <p>주소</p>

                <img src={summaryLocation} />
              </div>

              <p className='summary__value'>
                <small>{process.env.REACT_APP_COMPANY_ADDRESS}</small>
              </p>
            </div>

            <div className='summary'>
              <div className='summary__head'>
                <p>사업분야</p>

                <img src={summaryCategory} />
              </div>

              <p className='summary__value'>POS · KIOSK</p>
            </div>

            <div className='summary'>
              <div className='summary__head'>
                <p>대표전화</p>

                <img src={summaryTel} />
              </div>

              <p className='summary__value'>
                {process.env.REACT_APP_COMPANY_TEL}
              </p>
            </div>

            <div className='summary'>
              <div className='summary__head'>
                <p>FAX</p>

                <img src={summaryFax} />
              </div>

              <p className='summary__value'>
                {process.env.REACT_APP_COMPANY_FAX}
              </p>
            </div>

            <div className='summary'>
              <div className='summary__head'>
                <p>이메일</p>

                <img src={summaryEmail} />
              </div>

              <p className='summary__value'>
                <small>{process.env.REACT_APP_COMPANY_EMAIL}</small>
              </p>
            </div>
          </div>
        </OverviewSummary>
      </Container>

      <Container style={{ paddingTop: '0' }}>
        <OverviewCoreValue>
          <OverviewLabel>핵심가치</OverviewLabel>

          <Swiper
            speed={500}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              600: { slidesPerView: 2, spaceBetween: 15 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1200: { slidesPerView: 2, spaceBetween: 30 },
            }}
          >
            <SwiperSlide>
              <div className='core-value'>
                <img src={coreInnovation} />

                <div className='core-value__content'>
                  <img src={coreInnovationIcon} />
                  <p>혁신기술</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='core-value'>
                <img src={coreIdea} />

                <div className='core-value__content'>
                  <img src={coreIdeaIcon} />
                  <p>창의적 아이디어</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='core-value'>
                <img src={coreCustomer} />

                <div className='core-value__content'>
                  <img src={coreCustomerIcon} />
                  <p>고객중심</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='core-value'>
                <img src={coreChallenge} />

                <div className='core-value__content'>
                  <img src={coreChallengeIcon} />
                  <p>도전정신</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </OverviewCoreValue>
      </Container>
    </>
  );
};

export default Overview;
