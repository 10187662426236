// import styled, { css } from 'styled-components';
import styled, { css } from 'styled-components';
import { breakpoints } from './breakpoints.styles';

export const ContainerDefaultStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ContainerProps {
  $type?: 'fullWidth' | 'fitContentHeight';
  $background?: string;
  $overflow?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${ContainerDefaultStyle}
  padding: ${(props) => {
    switch (props.$type) {
      case 'fullWidth':
        return '10rem 0';
      case 'fitContentHeight':
        return '0 4rem';
      default:
        return '10rem 4rem';
    }
  }};
  background: ${(props) => props.$background ?? '#fff'};
  overflow: ${(props) => (props.$overflow ? 'visible' : 'hidden')};

  @media ${breakpoints.mobile} {
    padding: ${(props) => {
      switch (props.$type) {
        case 'fullWidth':
          return '8rem 0';
        case 'fitContentHeight':
          return '0 2rem';
        default:
          return '8rem 2rem';
      }
    }};
  }
`;

interface ContainerTitleProps {
  $alignStart?: boolean;
  $disableMarginBottom?: boolean;
}

export const ContainerTitle = styled.div<ContainerTitleProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$alignStart ? 'flex-start' : 'center')};
  gap: 1rem;
  margin-bottom: ${(props) => (props.$disableMarginBottom ? '0' : '10rem')};
  text-align: ${(props) => (props.$alignStart ? 'start' : 'center')};
  word-break: keep-all;

  p {
    color: var(--color-text);
    font-size: 5rem;
    font-weight: 700;

    strong {
      color: var(--color-primary);
    }

    img {
      display: inline-block;
      height: 1em;
    }
  }

  small {
    color: var(--color-primary);
    font-size: 1.8rem;
    font-weight: 700;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: ${(props) => (props.$disableMarginBottom ? '0' : '8rem')};

    p {
      font-size: 4rem;
    }

    small {
      font-size: 1.6rem;
    }
  }

  @media ${breakpoints.mobile} {
    margin-bottom: ${(props) => (props.$disableMarginBottom ? '0' : '5rem')};

    p {
      font-size: 3rem;
    }

    small {
      font-size: 1.4rem;
    }
  }
`;
