import { css } from 'styled-components';
import { breakpoints } from './breakpoints.styles';

export const mobileOnly = css`
  @media not ${breakpoints.mobile} {
    display: none;
  }
`;

export const notMobileOnly = css`
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

export const tabletOnly = css`
  @media not ${breakpoints.tablet} {
    display: none;
  }
`;

export const notTabletOnly = css`
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
