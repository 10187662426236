import styled from 'styled-components';
import contactBannerGreenBg from 'assets/img/common/contact_banner_green.png';
import contactBannerGreenBgMobile from 'assets/img/common/contact_banner_green_mobile.png';
import contactBannerBlueBg from 'assets/img/common/contact_banner_blue.png';
import contactBannerBlueBgMobile from 'assets/img/common/contact_banner_blue_mobile.png';
import { breakpoints } from 'styles/breakpoints.styles';

export type ContactBannerTypes = 'green' | 'blue';

interface ContactBannerProps {
  $type: ContactBannerTypes;
}

export const ContactBannerContainer = styled.div<ContactBannerProps>`
  --max-width: 130rem;
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.$type === 'blue' ? '7rem 4rem' : '10rem 4rem')};
  background: var(--background-cover)
    ${(props) =>
      props.$type === 'blue'
        ? `url(${contactBannerBlueBg})`
        : `url(${contactBannerGreenBg})`};

  .contact-banner {
    flex: 1;
    display: flex;
    align-items: ${(props) => (props.$type === 'blue' ? 'flex-end' : 'center')};
    gap: 4rem;
    max-width: var(--max-width);
    font-family: ${(props) =>
      props.$type === 'blue'
        ? 'var(--font-pretendard)'
        : 'var(--font-noto-sans)'};

    .contact-banner__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: ${(props) => (props.$type === 'blue' ? '2rem' : '0')};
      color: #fff;
      word-break: keep-all;
      text-wrap: balance;

      small {
        font-size: 1.8rem;
        font-weight: 300;
      }

      p {
        font-size: ${(props) => (props.$type === 'blue' ? '2rem' : '4.2rem')};
        font-weight: ${(props) => (props.$type === 'blue' ? '400' : '300')};
        line-height: 1.25;

        b {
          font-size: ${(props) =>
            props.$type === 'blue' ? '3.6rem' : '4.6rem'};
          font-weight: 700;
        }
      }
    }

    a {
      display: block;
      padding: ${(props) =>
        props.$type === 'blue' ? '1rem 9rem' : '1rem 4.5rem'};
      color: ${(props) =>
        props.$type === 'blue' ? '#fff' : 'var(--color-primary)'};
      background-color: ${(props) =>
        props.$type === 'blue' ? 'rgba(255, 255, 255, 0.2)' : '#fff'};
      font-size: ${(props) => (props.$type === 'blue' ? '1.8rem' : '2rem')};
      font-weight: 600;
      border: 0.1rem solid #fff;
      border-radius: ${(props) => (props.$type === 'blue' ? '0.5rem' : '0')};

      &:active {
        filter: brightness(0.9);
      }

      ${(props) =>
        props.$type === 'blue' &&
        `
          @media (hover: hover) {
            &:hover {
              color: #242424;
              background-color: #fff;
            }
          }
      `}
    }
  }

  @media ${breakpoints.tablet} {
    .contact-banner {
      .contact-banner__content {
        small {
          font-size: 1.6rem;
        }

        p {
          font-size: ${(props) =>
            props.$type === 'blue' ? '1.8rem' : '3.2rem'};

          b {
            font-size: ${(props) => (props.$type === 'blue' ? '3rem' : '4rem')};
          }
        }
      }

      a {
        padding: ${(props) =>
          props.$type === 'blue' ? '1rem 7rem' : '1rem 5rem'};
        font-size: 1.8rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    padding: 4rem 2rem;
    background-image: ${(props) =>
      props.$type === 'blue'
        ? `url(${contactBannerBlueBgMobile})`
        : `url(${contactBannerGreenBgMobile})`};

    .contact-banner {
      flex-direction: column;
      align-items: flex-end;
      gap: 2rem;

      .contact-banner__content {
        gap: 0;
        width: 100%;

        small {
          font-size: 1.4rem;
        }

        p {
          font-size: ${(props) =>
            props.$type === 'blue' ? '1.4rem' : '1.8rem'};

          b {
            font-size: ${(props) =>
              props.$type === 'blue' ? '2.6rem' : '2.4rem'};
          }
        }
      }

      a {
        padding: 1rem 5rem;
        font-size: 1.4rem;
      }
    }
  }
`;
