import styled from 'styled-components';
import { breakpoints } from './breakpoints.styles';

export const SubPageTop = styled.div`
  position: relative;
`;

export const SubPageBanner = styled.div`
  position: relative;
  aspect-ratio: 192 / 65;
  min-height: 35rem;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .banner__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: var(--max-width);
    padding: 4rem;
    margin: 0 auto;
    color: #fff;
    font-size: 6rem;
    font-weight: 700;

    small {
      font-size: 2rem;
      font-weight: 300;

      img {
        position: relative;
        display: inline-block;
        height: 1em;
        width: auto;
      }

      @media ${breakpoints.tablet} {
        font-size: 1.8rem;
      }
    }

    @media ${breakpoints.tablet} {
      font-size: 5rem;
    }

    @media ${breakpoints.mobile} {
      padding: 2rem;
      font-size: max(7vw, 4.5rem);
    }
  }

  @media ${breakpoints.mobile} {
    aspect-ratio: 39 / 63;
    max-height: min(60rem, 100vh);
  }
`;

export const SubPageNav = styled.div`
  --max-width: 120rem;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;

  ul {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;

    li {
      display: inherit;
      align-items: inherit;
      flex: 1;

      a {
        display: inherit;
        flex: inherit;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        color: #fff;
        font-weight: 700;
        background-color: rgba(255, 255, 255, 0.3);

        &.active {
          color: var(--color-primary);
          background-color: #fff;
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    position: relative;
    padding-top: 2rem;
    overflow: auto;

    ul {
      li {
        a {
          padding: 0.5rem 1.5rem;
          color: var(--color-text-footer);
          background-color: transparent;
          border-bottom: 0.2rem solid var(--color-border);
          font-weight: 400;

          &.active {
            font-weight: 700;
            border-color: var(--color-primary);
          }
        }
      }
    }
  }
`;
