import { Container, ContainerTitle } from 'styles/container.styles';
import { ProductContainerTitle } from '../styles';
import { CrmKnowHow, CrmProcess } from './styles';
import { breakpoints } from 'styles/breakpoints.styles';
import crmProcess from 'assets/img/product/crm/crm_process.png';
import crmProcessMobile from 'assets/img/product/crm/crm_process_mobile.png';
import crmKnowHow1 from 'assets/img/product/crm/crm_knowhow_1.png';
import crmKnowHow2 from 'assets/img/product/crm/crm_knowhow_2.png';
import crmKnowHow3 from 'assets/img/product/crm/crm_knowhow_3.png';
import crmKnowHow4 from 'assets/img/product/crm/crm_knowhow_4.png';
import crmKnowHow5 from 'assets/img/product/crm/crm_knowhow_5.png';

const Crm = () => {
  return (
    <>
      <Container $background='linear-gradient(100deg, #fefefe 7%, #e3effb 93%)'>
        <ProductContainerTitle data-aos='fade-up'>
          <p>
            <strong>CRM 플랫폼</strong>
          </p>
          <small>당사만의 모바일 전단지 쇼핑몰 제작 기술 및 노하우</small>
        </ProductContainerTitle>

        <CrmKnowHow>
          <div className='crm-knowhow'>
            <div className='crm-knowhow__content' data-aos='fade-right'>
              <small>Know-How 1.</small>

              <p>
                식자재마트에 리테일엑스의 솔루션을 제공함으로써 50,000여가지
                상품 이미지 보유
              </p>
            </div>

            <img src={crmKnowHow1} data-aos='fade-left' data-aos-delay='200' />
          </div>

          <div className='crm-knowhow'>
            <div className='crm-knowhow__content' data-aos='fade-left'>
              <small>Know-How 2.</small>

              <p>
                AI 기술을 접목하여 상품명이 들어있는 엑셀파일 업로드로 모바일
                전단지 제작완료
              </p>
            </div>

            <img src={crmKnowHow2} data-aos='fade-right' data-aos-delay='200' />
          </div>

          <div className='crm-knowhow'>
            <div className='crm-knowhow__content' data-aos='fade-right'>
              <small>Know-How 3.</small>

              <p>
                단 10분만에 100가지 이상의 상품이 들어있는 모바일 전단지 제작
                가능
              </p>
            </div>

            <img src={crmKnowHow3} data-aos='fade-left' data-aos-delay='200' />
          </div>

          <div className='crm-knowhow'>
            <div className='crm-knowhow__content' data-aos='fade-left'>
              <small>Know-How 4.</small>

              <p>모바일 전단지로 주문 및 PG 결제 ONE-STOP TOOL</p>
            </div>

            <img src={crmKnowHow4} data-aos='fade-right' data-aos-delay='200' />
          </div>

          <div className='crm-knowhow'>
            <div className='crm-knowhow__content' data-aos='fade-right'>
              <small>Know-How 5.</small>

              <p>
                고객 주문서를 관리자, Deliver, 고객에게 문자 자동 전송 및 주문서
                인쇄 기능
              </p>
            </div>

            <img src={crmKnowHow5} data-aos='fade-left' data-aos-delay='200' />
          </div>
        </CrmKnowHow>
      </Container>

      <Container>
        <ContainerTitle data-aos='fade-up'>
          <p>
            <strong>CRM</strong> PROCESS
          </p>
        </ContainerTitle>

        <CrmProcess data-aos='fade-up' data-aos-delay='100'>
          <picture>
            <source media={breakpoints.mobile} srcSet={crmProcessMobile} />
            <img src={crmProcess} />
          </picture>
        </CrmProcess>
      </Container>
    </>
  );
};

export default Crm;
