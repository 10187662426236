import { Link } from 'react-router-dom';
import { FooterContainer } from './styles';
import logo from 'assets/img/logo/retailx/logo.png';
import Select, { GroupBase, SelectInstance } from 'react-select';
import { useRef } from 'react';

type SelectOption = {
  value: string;
  label: string;
};

const selectOptions: SelectOption[] = [
  { value: 'http://runninglab.co.kr', label: '러닝랩' },
  { value: 'https://jkgkorea.kr', label: 'JKG KOREA' },
];

const Footer = () => {
  const selectRef =
    useRef<SelectInstance<SelectOption, false, GroupBase<SelectOption>>>(null);

  return (
    <FooterContainer>
      <div className='footer__wrapper'>
        <ul className='footer__menu'>
          <li>
            <Link to={'/company'}>회사소개</Link>
          </li>
          <li>
            <Link to={'/company/map'}>오시는 길</Link>
          </li>
          <li>
            <Link to={'/contact'}>고객문의</Link>
          </li>
          <li>
            <Link to={'/privacy'}>
              <b>개인정보처리방침</b>
            </Link>
          </li>
        </ul>

        <div className='footer__bottom'>
          <div className='footer__info'>
            <p className='footer__info__name'>
              {process.env.REACT_APP_COMPANY}
            </p>

            <div className='footer__info__wrapper'>
              <p>대표이사 {process.env.REACT_APP_CHIEF}</p>
              <p>주소 {process.env.REACT_APP_COMPANY_ADDRESS}</p>
              <p>사업자등록번호 {process.env.REACT_APP_COMPANY_CODE}</p>
              <p>TEL {process.env.REACT_APP_COMPANY_TEL}</p>
              <p>E-mail {process.env.REACT_APP_COMPANY_EMAIL}</p>
            </div>

            <p className='footer__copyright'>
              COPYRIGHT© 2024. {process.env.REACT_APP_COMPANY_NAME} All Right
              reserved.
            </p>
          </div>

          <div className='footer__family'>
            <img src={logo} alt='리테일엑스' className='footer__family__logo' />

            <Select
              ref={selectRef}
              value={null}
              options={selectOptions}
              isSearchable={false}
              menuPlacement='auto'
              onChange={(selectedOption, meta) => {
                if (selectedOption) {
                  open(selectedOption.value);
                }

                if (selectRef.current) {
                  selectRef.current.blur();
                }
              }}
              className='footer__family__select'
              classNamePrefix='footer__family__select'
              placeholder='Family Site'
            />
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
