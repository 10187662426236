import { breakpoints } from 'styles/breakpoints.styles';
import { SubPageBanner, SubPageTop } from 'styles/subPage.styles';
import noticeBanner from 'assets/img/notice/banner.png';
import noticeBannerMobile from 'assets/img/notice/banner_mobile.png';
import { Outlet } from 'react-router-dom';

const Notice = () => {
  return (
    <>
      <SubPageTop>
        <SubPageBanner>
          <picture>
            <source media={breakpoints.mobile} srcSet={noticeBannerMobile} />
            <img src={noticeBanner} />
          </picture>

          <div className='banner__content'>
            <p>공지사항</p>
          </div>
        </SubPageBanner>
      </SubPageTop>

      <Outlet />
    </>
  );
};

export default Notice;
