import { useEffect } from 'react';
import Header from 'components/Header';
import { Outlet, useLocation } from 'react-router-dom';
import { RootContainer } from './styles';
import Footer from 'components/Footer';
import { ToastContainer } from 'react-toastify';

const RootLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />

      <RootContainer>
        <Outlet />
      </RootContainer>

      <Footer />

      <ToastContainer theme='light' toastStyle={{ fontFamily: 'inherit' }} />
    </>
  );
};

export default RootLayout;
