import { useEffect } from 'react';
import Router from 'routers/Router';
import GlobalStyles from 'styles/global.styles';
import Aos from 'aos';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import relativeTime from 'dayjs/plugin/relativeTime';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

dayjs.locale('ko');
dayjs.extend(relativeTime);

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Router />
    </>
  );
}

export default App;
