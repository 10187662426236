import { Container } from 'styles/container.styles';
import { NoticeListContainer } from './styles';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { INotice } from 'types/notice.types';
import dayjs from 'dayjs';

interface INoticeList {
  notices: INotice[];
  count: number;
}

const NoticeList = () => {
  const [noticeList, setNoticeList] = useState<INoticeList>();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<INoticeList>('/notice');

      setNoticeList(data);
    })();
  }, []);

  return (
    <Container>
      <NoticeListContainer>
        {/* <div className='notice__head'>
          <p>공지사항</p>
        </div> */}

        <table className='notice__list'>
          <thead>
            <tr>
              <th>No</th>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
            </tr>
          </thead>

          <tbody>
            {noticeList && noticeList.count ? (
              noticeList.notices.map((notice, seq) => (
                <tr key={notice.id}>
                  <td>{noticeList.count - seq}</td>
                  <td>
                    <Link to={`${notice.id}`}>{notice.title}</Link>
                  </td>
                  <td>{notice.author.adminName}</td>
                  <td>
                    {dayjs(notice.timestamp.createdAt).format('YYYY-MM-DD')}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>공지사항이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </NoticeListContainer>
    </Container>
  );
};

export default NoticeList;
