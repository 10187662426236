import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';
import { ContainerTitle } from 'styles/container.styles';

export const ProductContainerTitle = styled(ContainerTitle)`
  flex: 1;
  gap: 2rem;

  small {
    color: var(--color-text-light);
    font-size: 2rem;
    font-weight: 400;

    &:first-child {
      font-size: 1.8rem;
    }
  }

  @media ${breakpoints.tablet} {
    small {
      font-size: 1.8rem;

      &:first-child {
        font-size: 1.6rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    small {
      font-size: 1.6rem;

      &:first-child {
        font-size: 1.4rem;
      }
    }
  }
`;
