import styled from 'styled-components';
import historyBanner from 'assets/img/company/history/history_banner.png';
import { breakpoints } from 'styles/breakpoints.styles';

export const HistoryBanner = styled.div`
  width: 100%;
  aspect-ratio: 192 / 50;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 12rem 4rem 6rem;
  background: var(--background-cover) url(${historyBanner});

  .history__banner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 3rem;
    max-width: var(--max-width);

    .history__banner__top {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      color: #fff;
      font-size: 3rem;
      font-weight: 600;
      word-break: keep-all;
      text-wrap: pretty;

      small {
        font-size: 1.8rem;
      }
    }

    .history__banner__bottom {
      color: #ffffff4c;
      font-size: 8rem;
      font-weight: 700;
    }
  }

  @media ${breakpoints.tablet} {
    padding: 8rem 4rem 4rem;

    .history__banner {
      .history__banner__top {
        font-size: 2.4rem;

        small {
          font-size: 1.6rem;
        }
      }

      .history__banner__bottom {
        font-size: 6rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    padding: 2.5rem 1.5rem;

    .history__banner {
      .history__banner__top {
        gap: 1rem;
        font-size: 1.6rem;

        small {
          font-size: 1.4rem;
        }
      }

      .history__banner__bottom {
        font-size: 4rem;
      }
    }
  }
`;

export const HistoryList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20rem;
  width: 100%;
  max-width: var(--max-width);

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: -20rem;
    height: calc(100% + 18rem);
    width: 0.1rem;
    background-color: var(--color-border);
  }

  .history {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 15rem;
    width: 100%;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
      background-color: #fff;
      border: 0.2rem solid var(--color-primary);
      border-radius: 50%;
    }

    .history__left {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .history__year {
        color: var(--color-primary);
        font-size: 4.4rem;
        font-weight: 700;
        line-height: 1;
      }
    }

    .history__right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4rem;
      padding-bottom: 0.8rem;

      .history__row {
        position: relative;
        display: grid;
        grid-template-columns: 3rem 1fr;
        gap: 2rem;

        .history__month {
          font-size: 2rem;
          font-weight: 700;
          white-space: nowrap;
        }

        .history__content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          color: var(--color-text-light);
          word-break: keep-all;
          text-wrap: pretty;

          p {
            font-size: 1.8rem;

            small {
              font-size: 1.6rem;
              font-weight: 600;
            }
          }

          img {
            max-height: 25rem;
          }
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    gap: 16rem;

    &::before {
      left: 20rem;
    }

    .history {
      display: grid;
      grid-template-columns: 20rem 1fr;
      gap: 4rem;

      &::before {
        left: 19.25rem;
      }

      .history__left {
        .history__year {
          font-size: 4rem;
          padding-right: 4rem;
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    gap: 12rem;

    &::before {
      top: -16rem;
      left: 0;
      height: calc(100% + 15rem);
    }

    .history {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 2rem;

      &::before {
        bottom: 1rem;
        left: -0.5rem;
        height: 1rem;
        width: 1rem;
      }

      .history__left {
        order: 2;

        .history__year {
          font-size: 3rem;
        }
      }

      .history__right {
        order: 1;

        .history__row {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .history__content {
            p {
              font-size: 1.6rem;

              small {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
`;
