import { breakpoints } from 'styles/breakpoints.styles';
import { SubPageBanner, SubPageTop } from 'styles/subPage.styles';
import contactBanner from 'assets/img/contact/banner.png';
import contactBannerMobile from 'assets/img/contact/banner_mobile.png';
import { Container } from 'styles/container.styles';
import { ContactContainer, ContactSubmit } from './styles';
import { useForm } from 'react-hook-form';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { ITerm } from 'types/term.types';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ContactFormFields {
  name: string;
  phone: string;
  email: string;
  type: string;
  product: string;
  content: string;
  term: boolean;
}

const Contact = () => {
  const navigate = useNavigate();

  const [privacy, setPrivacy] = useState<ITerm>();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<ITerm>(`/term/privacy`);

      setPrivacy(data);
    })();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormFields>();

  const onSubmit = handleSubmit(async (formData) => {
    const { name, phone, email, type, product, content } = formData;

    const { status, data } = await axios.post<{
      estimateId: number;
      message: string;
    }>('/contact', {
      name,
      phone,
      email,
      type,
      product,
      content,
    });

    if (status === 200) {
      toast.success(data.message);
      navigate('/');
    } else {
      toast.error(data.message);
    }
  });

  return (
    <>
      <SubPageTop>
        <SubPageBanner>
          <picture>
            <source media={breakpoints.mobile} srcSet={contactBannerMobile} />
            <img src={contactBanner} />
          </picture>

          <div className='banner__content'>
            <p>고객문의</p>
          </div>
        </SubPageBanner>
      </SubPageTop>

      <Container>
        <ContactContainer>
          <form autoComplete='off' onSubmit={onSubmit}>
            <div className='contact__wrapper'>
              <div className='contact__wrapper__head'>
                <p>문의사항을 입력해주세요</p>
              </div>

              <div className='contact__form__row'>
                <label htmlFor='name'>이름</label>

                <input
                  {...register('name', { required: '이름을 입력해주세요' })}
                  type='text'
                  id='name'
                  placeholder='이름을 입력해주세요'
                />

                {errors.name && (
                  <p className='contact__form__error'>
                    {`${errors.name.message}`}
                  </p>
                )}
              </div>

              <div className='contact__form__row'>
                <label htmlFor='phone'>휴대폰번호</label>

                <input
                  {...register('phone', {
                    required: '휴대폰번호를 입력해주세요',
                  })}
                  type='tel'
                  id='phone'
                  placeholder='"-" 구분없이 숫자만 입력해주세요'
                  inputMode='numeric'
                />

                {errors.phone && (
                  <p className='contact__form__error'>
                    {`${errors.phone.message}`}
                  </p>
                )}
              </div>

              <div className='contact__form__row'>
                <label htmlFor='email'>이메일</label>

                <input
                  {...register('email', {
                    required: '이메일을 입력해주세요',
                  })}
                  type='email'
                  id='email'
                  placeholder='답변 받으실 이메일을 입력해주세요'
                  inputMode='email'
                />

                {errors.email && (
                  <p className='contact__form__error'>
                    {`${errors.email.message}`}
                  </p>
                )}
              </div>

              <div className='contact__form__row'>
                <label htmlFor='type'>문의 유형</label>

                <select
                  {...register('type', {
                    required: '문의 유형을 선택해주세요',
                  })}
                  id='type'
                >
                  <option value=''>문의 유형을 선택해주세요</option>
                  <option>가입 문의</option>
                  <option>사용 문의</option>
                  <option>기타 문의</option>
                </select>

                {errors.type && (
                  <p className='contact__form__error'>
                    {`${errors.type.message}`}
                  </p>
                )}
              </div>

              <div className='contact__form__row'>
                <label htmlFor='product'>문의 제품</label>

                <select
                  {...register('product', {
                    required: '문의 제품을 선택해주세요',
                  })}
                  id='product'
                >
                  <option value=''>문의하실 제품을 선택해주세요</option>
                  <option>POS 시스템</option>
                  <option>KIOSK</option>
                  <option>무인매장 원격관리</option>
                  <option>쇼핑몰 APP빌더</option>
                  <option>CRM 플랫폼</option>
                  <option>기타</option>
                </select>

                {errors.product && (
                  <p className='contact__form__error'>
                    {`${errors.product.message}`}
                  </p>
                )}
              </div>

              <div className='contact__form__row'>
                <label htmlFor='content'>문의 내용</label>

                <ReactTextareaAutosize
                  {...register('content', {
                    required: '문의 내용을 입력해주세요',
                  })}
                  id='content'
                  placeholder='문의 내용을 입력해주세요'
                  minRows={3}
                  maxRows={10}
                ></ReactTextareaAutosize>

                {errors.content && (
                  <p className='contact__form__error'>
                    {`${errors.content.message}`}
                  </p>
                )}
              </div>
            </div>

            <div className='contact__wrapper'>
              <div className='contact__wrapper__head'>
                <p>개인정보 수집 및 이용 동의</p>
              </div>

              <div className='contact__form__row'>
                {privacy && (
                  <p className='contact__form__term'>{privacy.content}</p>
                )}
              </div>

              <input
                {...register('term', {
                  required: '개인정보 수집 및 이용에 동의해주세요',
                })}
                type='checkbox'
                id='term'
              />
              <label htmlFor='term'>개인정보 수집 및 이용에 동의합니다.</label>

              {errors.term && (
                <p className='contact__form__error'>
                  {`${errors.term.message}`}
                </p>
              )}
            </div>

            <ContactSubmit disabled={isSubmitting}>문의하기</ContactSubmit>
          </form>
        </ContactContainer>
      </Container>
    </>
  );
};

export default Contact;
