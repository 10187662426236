import { useEffect, useState } from 'react';
import { ITerm } from 'types/term.types';
import axios from 'axios';
import { breakpoints } from 'styles/breakpoints.styles';
import { Container } from 'styles/container.styles';
import { SubPageBanner, SubPageTop } from 'styles/subPage.styles';
import noticeBanner from 'assets/img/notice/banner.png';
import noticeBannerMobile from 'assets/img/notice/banner_mobile.png';
import { PrivacyContainer } from './styles';

const Privacy = () => {
  const [privacy, setPrivacy] = useState<ITerm>();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<ITerm>(`/term/privacy`);

      setPrivacy(data);
    })();
  }, []);

  return (
    <>
      <SubPageTop>
        <SubPageBanner>
          <picture>
            <source media={breakpoints.mobile} srcSet={noticeBannerMobile} />
            <img src={noticeBanner} />
          </picture>

          <div className='banner__content'>
            <p>개인정보처리방침</p>
          </div>
        </SubPageBanner>
      </SubPageTop>

      <Container>
        <PrivacyContainer>
          {privacy && <p className='privacy__content'>{privacy.content}</p>}
        </PrivacyContainer>
      </Container>
    </>
  );
};

export default Privacy;
