import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Container, ContainerTitle } from 'styles/container.styles';
import { ManlessFunction, ManlessSystem } from './styles';
import { ProductContainerTitle } from '../styles';
import manlessStore from 'assets/img/product/manless/manless_store.png';
import manlessMonitoring from 'assets/img/product/manless/manless_monitoring.png';
import manlessDoor from 'assets/img/product/manless/manless_door.png';
import manlessDoorActive from 'assets/img/product/manless/manless_door_active.png';
import manlessCamera from 'assets/img/product/manless/manless_camera.png';
import manlessCameraActive from 'assets/img/product/manless/manless_camera_active.png';
import manlessSpeaker from 'assets/img/product/manless/manless_speaker.png';
import manlessSpeakerActive from 'assets/img/product/manless/manless_speaker_active.png';
import manlessError from 'assets/img/product/manless/manless_error.png';
import manlessErrorActive from 'assets/img/product/manless/manless_error_active.png';
import manlessHistory from 'assets/img/product/manless/manless_history.png';
import manlessHistoryActive from 'assets/img/product/manless/manless_history_active.png';
import manlessAlram from 'assets/img/product/manless/manless_alram.png';
import manlessAlramActive from 'assets/img/product/manless/manless_alram_active.png';

const Manless = () => {
  return (
    <>
      <Container $type='fullWidth'>
        <ContainerTitle data-aos='fade-up'>
          <p>
            무인매장 <strong>원격관리 시스템</strong>
          </p>
        </ContainerTitle>

        <ManlessSystem data-aos='fade-up'>
          <div className='manless-system__store'>
            <p>
              24시간 운영이 가능한 매장의 인력 최소화로{' '}
              <strong>자동화 운영 실현 가능</strong>
            </p>

            <img src={manlessStore} />
          </div>

          <div className='manless-system__monitoring'>
            <img src={manlessMonitoring} />

            <p>
              <strong>실시간 모니터링</strong>으로 간편관리
            </p>

            <small>
              무인매장을 점주 스마트폰으로 간편하게 제어 가능한 솔루션 실시간
              모니터링으로 매장 운영의 안정성과 신뢰성을 유지 인력을
              최소화함으로써 매장 운영비용을 절감하여 경제적으로 운영
            </small>
          </div>
        </ManlessSystem>
      </Container>

      <Container>
        <ProductContainerTitle data-aos='fade-up'>
          <small>다양한 솔루션과 서비스로 이루는 비즈니스 성공</small>
          <p>원격관리 시스템 주기능</p>
        </ProductContainerTitle>

        <ManlessFunction>
          <Swiper
            speed={500}
            slidesPerView={'auto'}
            spaceBetween={10}
            breakpoints={{
              768: { slidesPerView: 'auto', spaceBetween: 20 },
              1200: { slidesPerView: 6, spaceBetween: 0 },
            }}
            modules={[Pagination]}
            pagination
          >
            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='100'>
                <div className='manless-function'>
                  <p>출입내역 확인 및 출입문 일시적 개방</p>

                  <img src={manlessDoor} />
                  <img className='active' src={manlessDoorActive} />
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='200'>
                <div className='manless-function'>
                  <p>카메라를 통한 방범 관제</p>

                  <img src={manlessCamera} />
                  <img className='active' src={manlessCameraActive} />
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='300'>
                <div className='manless-function'>
                  <p>점포 상황별 음성 제공 및 스피커 음량 조절</p>

                  <img src={manlessSpeaker} />
                  <img className='active' src={manlessSpeakerActive} />
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='400'>
                <div className='manless-function'>
                  <p>출입인증 단말기 오류시 재부팅</p>

                  <img src={manlessError} />
                  <img className='active' src={manlessErrorActive} />
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='500'>
                <div className='manless-function'>
                  <p>방문정보 이력검색</p>

                  <img src={manlessHistory} />
                  <img className='active' src={manlessHistoryActive} />
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='600'>
                <div className='manless-function'>
                  <p>특이사항 알람기능</p>

                  <img src={manlessAlram} />
                  <img className='active' src={manlessAlramActive} />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </ManlessFunction>
      </Container>
    </>
  );
};

export default Manless;
