import { createGlobalStyle } from 'styled-components';
import { breakpoints } from './breakpoints.styles';
import 'swiper/css';
import 'swiper/css/pagination';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/fonts.css';
import './css/reset.css';
import './css/swiper.css';
import './css/react-toastify.css';

const GlobalStyles = createGlobalStyle`
  :root {
    --color-primary: #076238;

    --color-positive: #5b77ff;
    --color-negative: #ff5b5b;

    --color-text: #000000;
    --color-text-light: #555555;
    --color-text-footer: #666666;
    --color-text-footer-light: #aaaaaa;

    --color-bg: #ffffff;
    --color-grey-bg: #f8f8f8;
    --color-footer-bg: #f3f5f7;
    
    --color-border: #e8e8e8;
    --color-border-light: #f1f2f7;

    --font-pretendard: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    --font-s-core-dream: "SCoreDream", sans-serif;
    --font-noto-sans: "Noto Sans KR", sans-serif;

    --max-width: 124rem;

    --height-header: 8rem;

    --background-dimmer: rgba(0, 0, 0, 0.5);
    --background-loading: rgba(0, 0, 0, 0.2);

    --background-cover: no-repeat center / cover;

    @media ${breakpoints.mobile} {
      --height-header: 6rem;
    }
  }

  *, :after, :before {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  a {
    color: inherit;
    text-decoration:none;
  }

  html {
    font-size: 62.5%;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  body {
    position: relative;
    height: 100vh;
    color: var(--color-text);
    background-color: var(--color-bg);
    font-family: var(--font-pretendard);
    font-size: 1.6rem;

    @media ${breakpoints.mobile} {
      &.mobile-scroll-disabled {
        overflow: hidden;
      }
    }
  }
`;

export default GlobalStyles;
