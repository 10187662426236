import { Container, ContainerTitle } from 'styles/container.styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PosAi, PosBanner, PosBusinessArea, PosSoftware } from './styles';
import softwareEslIcon from 'assets/img/product/pos/software_esl_icon.png';
import softwarePopIcon from 'assets/img/product/pos/software_pop_icon.png';
import softwareEsl from 'assets/img/product/pos/software_esl.png';
import softwarePop from 'assets/img/product/pos/software_pop.png';
import businessArea from 'assets/img/product/pos/business_area.png';
import businessAreaRetail from 'assets/img/product/pos/business_area_retail.png';
import businessAreaFranchise from 'assets/img/product/pos/business_area_franchise.png';
import businessAreaMart from 'assets/img/product/pos/business_area_mart.png';
import businessAreaRestaurant from 'assets/img/product/pos/business_area_restaurant.png';
import businessAreaEtc from 'assets/img/product/pos/business_area_etc.png';

const Pos = () => {
  return (
    <>
      <Container>
        <PosAi>
          <div className='pos-ai__head'>
            <ContainerTitle $alignStart $disableMarginBottom>
              <small data-aos='fade-right'>혁신적인 AI 기술</small>
              <p data-aos='fade-right' data-aos-delay='100'>
                <strong>AI TECHNOLOGY</strong>
              </p>
            </ContainerTitle>
            <p data-aos='fade-left'>
              <b>「 마트통 포스 」</b>는 AI를 활용하여 다양한 방면으로
              <br /> 효율적이고 정확한 데이터 분석 및 활용이 가능합니다
            </p>
          </div>

          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            breakpoints={{
              768: { slidesPerView: 2, spaceBetween: 20 },
              1200: { slidesPerView: 3, spaceBetween: 30 },
            }}
          >
            <SwiperSlide>
              <div data-aos='fade-left'>
                <div className='pos-ai'>
                  <h5>매입가 동적 조정기능</h5>
                  <p>
                    원자재 및 부품의 가격은 수시로 변동합니다.
                    <br />
                    AI를 활용하여 실시간으로 모니터링하고 변화에 따라{' '}
                    <b>
                      매입가를 조정함으로써 변동성에 대응할 수 있습니다.
                    </b>{' '}
                    매입가를 동적으로 조정함으로써 비용을 최적화하고 마진을
                    향상시킬 수 있습니다.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='100'>
                <div className='pos-ai'>
                  <h5>재고 최적화 예측</h5>
                  <p>
                    과거 데이터 및 다양한 외부 요인을 분석하여 미래 수요를 예측
                    합니다.
                    <br />
                    이는 <b>매장의 재고를 효과적으로 계획</b>하고 예측하는 데
                    도움을 주어 과다 재고로 자금을 소모하고, 창고 비용을
                    증가시키는 것을 방지할 수 있습니다.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div data-aos='fade-left' data-aos-delay='200'>
                <div className='pos-ai'>
                  <h5>판매가 동적조정기능</h5>
                  <p>
                    끊임없이 변화하는 시장 조건 속에서 AI를 활용한 동적 가격
                    조정은 실시간으로 시장 변화를 감지하고 신속하게 대응할 수
                    있도록 도와주어{' '}
                    <b>최적의 가격으로 제품을 판매하도록 하여 수익을 극대화</b>
                    합니다.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </PosAi>
      </Container>

      <PosBanner $type='fitContentHeight'>
        <div className='pos-banner__content'>
          <p>
            업계 30년 경력으로 만들어진
            <br />
            <strong>독보적인 POS 시스템</strong>
          </p>
        </div>
      </PosBanner>

      <Container>
        <ContainerTitle>
          <p data-aos='fade-up'>
            <strong>ESL · POP</strong> 프로그램 제공
          </p>
        </ContainerTitle>

        <PosSoftware>
          <div className='pos-software'>
            <h5 data-aos='fade-right'>
              <b>ESL 프로그램은</b> 이런 서비스 입니다.
            </h5>

            <div className='pos-software__grid'>
              <div
                className='pos-software__main'
                data-aos='fade-right'
                data-aos-delay='100'
              >
                <div className='pos-software__main__content'>
                  <div>
                    <img src={softwareEslIcon} />
                    <p>실시간 가격변동 반영시스템</p>
                  </div>

                  <p>
                    전자 스마트 라벨 ESL은 실시간 즉각 가격변동으로 종이사용
                    최소화가 가능한 Paperless, 친환경기술 입니다.
                  </p>
                </div>

                <img src={softwareEsl} />
              </div>
              <div
                className='pos-software__description'
                data-aos='fade-left'
                data-aos-delay='200'
              >
                <div>
                  <p>가격표시 오류 최소화를 통해 손실과 고객분쟁 사전 예방</p>
                  <p>
                    <small>(현재 종이 가격 표시 오류율 : 평균 3-5% 수준)</small>
                  </p>
                </div>
                <div>
                  <p>종이사용 최소화 가능</p>
                </div>
                <div>
                  <p>효율적인 업무 가능</p>
                  <p>
                    <small>
                      (종이 가격표 교체 평균 시간 : 3시간 - 9시간 ESL 가격 변경
                      평균 시간 : 1분 - 5분)
                    </small>
                  </p>
                </div>
                <div>
                  <p>프로모션 기능</p>
                  <p>
                    <small>(POP 광고 대체 · 실시간 광고 · 예약 광고)</small>
                  </p>
                </div>
                <div>
                  <p>온라인 실시간 가격비교, 동기화를 통한 고객 유출 방지</p>
                </div>
                <div>
                  <p>빅데이터 기반 다양한 프로모션 서비스 활용 가능</p>
                </div>
                <div>
                  <p>상품별 지정 시간별 재고현황 관리 가능</p>
                  <p>
                    <small>
                      (창고재고 · 판매재고 · 매대재고(카트 포함) 재고상황 파악
                      가능)
                    </small>
                  </p>
                </div>
                <div>
                  <p>차별화 된 고객 서비스 제공(UWB 적용시)</p>
                  <p>
                    <small>
                      (디지털 쿠폰 제공, 로열티 프로그램 통한 개인별 서비스
                      제공)
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='pos-software'>
            <h5 data-aos='fade-left'>
              <b>POP 프로그램은</b> 이런 서비스 입니다.
            </h5>

            <div className='pos-software__grid'>
              <div
                className='pos-software__main'
                data-aos='fade-left'
                data-aos-delay='100'
              >
                <div className='pos-software__main__content'>
                  <div>
                    <img src={softwarePopIcon} />
                    <p>상품 홍보 이미지 제작 프로그램</p>
                  </div>
                  <p>
                    마트나 매장에서 쇼핑을 하다보면 보이는 가격 · 세일
                    정보를안내하는 문구로 소바지가 물건을 구매하는 시점에서
                    이루어지는 모든 종류의 상품 광고
                  </p>
                </div>

                <img src={softwarePop} />
              </div>
              <div
                className='pos-software__description'
                data-aos='fade-right'
                data-aos-delay='200'
              >
                <div>
                  <p>
                    마트나 매장에서 쇼핑 하다보면 보이는 가격 · 세일 정보를
                    안내하는 문구
                  </p>
                </div>
                <div>
                  <p>
                    매장에서 즉각적으로 소비자의 구매를 촉진하기 위한 광고 매체
                  </p>
                </div>
                <div>
                  <p>
                    손쉬운 제동 제작 프로그램으로 100여가지의 템플릿을 기본 제공
                  </p>
                </div>
              </div>
            </div>
          </div>
        </PosSoftware>
      </Container>

      <Container $type='fullWidth'>
        <PosBusinessArea>
          <img src={businessArea} />

          <div className='business-area__content'>
            <div className='business-area__content__head'>
              <h5 data-aos='fade-left'>BUSINESS AREA</h5>
              <p data-aos='fade-left' data-aos-delay='100'>
                다양한 사업분야에서 활용 가능한 마트통 포스
              </p>
            </div>

            <div className='business-area__list'>
              <div className='business-area' data-aos='fade-left'>
                <img src={businessAreaRetail} />
                <p>도소매 유통업체</p>
              </div>

              <div
                className='business-area'
                data-aos='fade-left'
                data-aos-delay='100'
              >
                <img src={businessAreaFranchise} />
                <p>프랜차이즈</p>
              </div>

              <div
                className='business-area'
                data-aos='fade-left'
                data-aos-delay='200'
              >
                <img src={businessAreaMart} />
                <p>마트 · 편의점</p>
              </div>

              <div
                className='business-area'
                data-aos='fade-left'
                data-aos-delay='300'
              >
                <img src={businessAreaRestaurant} />
                <p>식당 · 외식업체</p>
              </div>

              <div
                className='business-area'
                data-aos='fade-left'
                data-aos-delay='400'
              >
                <img src={businessAreaEtc} />
                <p>기타 가맹점</p>
              </div>
            </div>
          </div>
        </PosBusinessArea>
      </Container>
    </>
  );
};

export default Pos;
