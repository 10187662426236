import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const GreetingTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: var(--max-width);
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.25;
  word-break: keep-all;
  text-wrap: balance;

  small {
    color: var(--color-primary);
    font-size: 1.8rem;
    font-weight: 700;
  }

  img {
    display: inline-block;
    height: 1em;
  }

  @media ${breakpoints.tablet} {
    font-size: 3.8rem;

    small {
      font-size: 1.6rem;
    }
  }

  @media ${breakpoints.mobile} {
    font-size: 2.6rem;

    small {
      font-size: 1.4rem;
    }
  }
`;

export const GreetingContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  max-width: var(--max-width);
  word-break: keep-all;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -15rem;
    height: 10rem;
    width: 0.1rem;
    background-color: var(--color-primary);
  }

  picture {
    flex: 1;
  }

  .greeting__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h5 {
      margin-bottom: 2rem;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: 1.36;
      text-wrap: balance;

      strong {
        color: var(--color-primary);
      }
    }

    p {
      color: var(--color-text-light);
      font-size: 1.8rem;
      line-height: 1.67;
      text-wrap: preety;

      &:has(b) {
        align-self: flex-end;
        margin-top: 4rem;
      }

      b {
        color: var(--color-text);
        font-weight: 600;
      }
    }
  }

  @media not ${breakpoints.tablet} {
    picture {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 15%;
        height: 80%;
        width: 200%;
        background-color: #7cadce;
        z-index: 1;
      }

      img {
        position: relative;
        z-index: 2;
      }
    }
  }

  @media ${breakpoints.tablet} {
    &::before {
      left: 0;
    }

    picture {
      display: none;
    }
  }

  @media ${breakpoints.mobile} {
    &::before {
      top: -10.5rem;
      height: 5rem;
    }

    .greeting__content {
      gap: 1.5rem;

      h5 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.6rem;
      }
    }
  }
`;
