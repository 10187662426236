import { CSSProperties } from 'react';

export const kakaoMapStyles: CSSProperties = {
  height: '100%',
  width: '100%',
};

export const mapCenter = new kakao.maps.LatLng(37.4732656, 126.884282);

export const kakaoMapCenter = {
  lat: mapCenter.getLat(),
  lng: mapCenter.getLng(),
};
