import { Container } from 'styles/container.styles';
import { NoticeDetailContainer } from './styles';
import { INotice } from 'types/notice.types';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';

const NoticeDetail = () => {
  const params = useParams();
  const [notice, setNotice] = useState<INotice>();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<INotice>(`/notice/${params.id}`);

      setNotice(data);
    })();
  }, [params]);

  return (
    <Container>
      {notice && (
        <NoticeDetailContainer>
          <div className='notice__head'>
            <h3 className='notice__title'>{notice.title}</h3>

            <div className='notice__info'>
              <p className='notice__author'>{notice.author.adminName}</p>
              <p className='notice__date'>
                {dayjs(notice.timestamp.createdAt).format('YYYY-MM-DD')}
              </p>
            </div>
          </div>

          <div
            className='notice__content'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(notice.content, {
                ADD_TAGS: ['iframe'],
                ADD_ATTR: [
                  'allow',
                  'allowfullscreen',
                  'frameborder',
                  'scrolling',
                ],
              }),
            }}
          ></div>
        </NoticeDetailContainer>
      )}
    </Container>
  );
};

export default NoticeDetail;
