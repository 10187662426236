import { Container } from 'styles/container.styles';
import {
  KioskAutomation,
  KioskExpect,
  KioskGrowth,
  KioskMethod,
} from './styles';
import KioskGrowthGraph from 'assets/img/product/kiosk/kiosk_growth.png';
import KioskAutomationBg from 'assets/img/product/kiosk/kiosk_automation_bg.png';
import KioskAutomationImg from 'assets/img/product/kiosk/kiosk_automation.png';
import KioskInquiryImg from 'assets/img/product/kiosk/kiosk_inquiry.png';
import KioskMethodCard from 'assets/img/product/kiosk/kiosk_method_card.png';
import KioskMethodReceipt from 'assets/img/product/kiosk/kiosk_method_receipt.png';
import KioskMethodPay from 'assets/img/product/kiosk/kiosk_method_pay.png';
import KioskMethodSamsung from 'assets/img/product/kiosk/kiosk_method_samsung.png';
import KioskMethodKakao from 'assets/img/product/kiosk/kiosk_method_kakao.png';
import KioskMethodNaver from 'assets/img/product/kiosk/kiosk_method_naver.png';
import KioskExpectCost from 'assets/img/product/kiosk/kiosk_expect_cost.png';
import KioskExpectManage from 'assets/img/product/kiosk/kiosk_expect_manage.png';
import KioskExpectCustom from 'assets/img/product/kiosk/kiosk_expect_custom.png';
import KioskExpectPromotion from 'assets/img/product/kiosk/kiosk_expect_promotion.png';
import { ProductContainerTitle } from '../styles';

const Kiosk = () => {
  return (
    <>
      <Container>
        <KioskGrowth>
          <ProductContainerTitle
            $alignStart
            $disableMarginBottom
            data-aos='fade-right'
          >
            <p>
              꾸준히 <strong>성장</strong>하는
              <br />
              키오스크 시장
            </p>

            <small>
              매장 운영의 효율성을 높이고 인건비 상승을 해결할 수 있는 키오스크
              시장은 매년 꾸준히 성장하고 있습니다. 리테일엑스와 함께
              시작해보세요.
            </small>
          </ProductContainerTitle>

          <img
            src={KioskGrowthGraph}
            data-aos='fade-left'
            data-aos-delay='200'
          />
        </KioskGrowth>
      </Container>

      <Container
        $background={`no-repeat bottom / cover url(${KioskAutomationBg})`}
        style={{ paddingBottom: '0' }}
      >
        <KioskAutomation>
          <ProductContainerTitle $disableMarginBottom data-aos='fade-up'>
            <p>
              매장 운영의 자동화
              <br />
              키오스크와 함께라면 가능해요
            </p>

            <small>인건비를 낮춰 운영비용은 줄이고 서비스는 올리고</small>
          </ProductContainerTitle>

          <img
            src={KioskAutomationImg}
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </KioskAutomation>
      </Container>

      <Container>
        <KioskAutomation>
          <ProductContainerTitle $disableMarginBottom data-aos='fade-up'>
            <p>
              언제 어디서든
              <br />
              가능한 실시간 매출조회
            </p>

            <small>PC와 휴대폰으로 간편하게 매출을 조회할 수 있어요</small>
          </ProductContainerTitle>

          <img src={KioskInquiryImg} data-aos='fade-up' data-aos-delay='100' />
        </KioskAutomation>
      </Container>

      <Container $background='var(--color-grey-bg)'>
        <KioskMethod>
          <ProductContainerTitle $disableMarginBottom data-aos='fade-up'>
            <p>다양한 결제수단 지원</p>

            <small>
              카드,삼성페이, 간편결제 등 다양한 결제수단을 지원합니다
            </small>
          </ProductContainerTitle>

          <div className='kiosk-method__list'>
            <div className='kiosk-method'>
              <img src={KioskMethodCard} />
              <p>카드</p>
            </div>

            <div className='kiosk-method'>
              <img src={KioskMethodReceipt} />
              <p>현금영수증</p>
            </div>

            <div className='kiosk-method'>
              <img src={KioskMethodPay} />
              <p>카드사페이</p>
            </div>

            <div className='kiosk-method'>
              <img src={KioskMethodSamsung} />
              <p>삼성페이</p>
            </div>

            <div className='kiosk-method'>
              <img src={KioskMethodKakao} />
              <p>카카오페이</p>
            </div>

            <div className='kiosk-method'>
              <img src={KioskMethodNaver} />
              <p>네이버페이</p>
            </div>
          </div>
        </KioskMethod>
      </Container>

      <Container>
        <KioskExpect>
          <ProductContainerTitle $disableMarginBottom data-aos='fade-up'>
            <small>무인 키오스크 · 모바일 키오스크</small>

            <p>
              <strong>KIOSK</strong> 기대효과
            </p>
          </ProductContainerTitle>

          <div className='kiosk-expect__list'>
            <div className='kiosk-expect' data-aos='fade-left'>
              <img src={KioskExpectCost} />

              <div className='kiosk-expect__content'>
                <h5>
                  매장 운영 <strong>비용은 줄이고 서비스는 높이고!</strong>
                </h5>

                <p>
                  고객들이 주문을 위해 대기하는 시간을 줄일 수 있고, 직원
                  리소스를 효율적으로 활용할 수 있어 매장 운영 비용을 절감할 수
                  있습니다.
                </p>
              </div>
            </div>

            <div
              className='kiosk-expect'
              data-aos='fade-left'
              data-aos-delay='100'
            >
              <img src={KioskExpectManage} />

              <div className='kiosk-expect__content'>
                <h5>
                  어디서나 <strong>간편한 상품관리</strong>
                </h5>

                <p>
                  카테고리/메뉴등록부터 수정까지 간편하게! 할인율과 옵션 설정,
                  품절 & 숨김 등 디테일한 상품관리가 가능합니다.
                </p>
              </div>
            </div>

            <div
              className='kiosk-expect'
              data-aos='fade-left'
              data-aos-delay='200'
            >
              <img src={KioskExpectCustom} />

              <div className='kiosk-expect__content'>
                <h5>
                  메뉴 커스텀이 가능한 <strong>옵션 설정 기능</strong>
                </h5>

                <p>
                  메뉴별로 상세한 옵션 설정이 가능하여 손님이 카운터에
                  방문하시지 않아도 키오스크를 통해 메뉴커스텀이 가능하여 주문
                  대기시간이 단축됩니다.
                </p>
              </div>
            </div>

            <div
              className='kiosk-expect'
              data-aos='fade-left'
              data-aos-delay='300'
            >
              <img src={KioskExpectPromotion} />

              <div className='kiosk-expect__content'>
                <h5>
                  홍보 화면<strong>으로 활용 가능한 화면</strong>
                </h5>

                <p>
                  키오스크의 대기화면과 판매페이지 상단 배너영역은 이미지를
                  수정, 변경 할 수 있어 매장의 대표 메뉴를 알리거나 계절상품,
                  이벤트 등을 고객에게 홍보할 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        </KioskExpect>
      </Container>
    </>
  );
};

export default Kiosk;
