import styled from 'styled-components';
import { Container, ContainerTitle } from 'styles/container.styles';
import { breakpoints } from 'styles/breakpoints.styles';
import ai1 from 'assets/img/product/pos/ai_1.png';
import ai2 from 'assets/img/product/pos/ai_2.png';
import ai3 from 'assets/img/product/pos/ai_3.png';
import posBanner from 'assets/img/product/pos/pos_banner_with_img.png';
import posBannerMobile from 'assets/img/product/pos/pos_banner_mobile.png';
import softwareCheck from 'assets/img/product/pos/software_check.png';

export const PosAi = styled.div`
  width: 100%;
  max-width: var(--max-width);
  word-break: keep-all;
  text-wrap: pretty;

  .pos-ai__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10rem;

    ${ContainerTitle} {
      small {
        color: var(--color-text);
        font-weight: 400;
      }
    }

    & > p {
      font-size: 1.8rem;
      text-align: center;

      b {
        font-weight: 700;
      }
    }
  }

  .swiper {
    overflow: visible;

    .swiper-wrapper {
      .swiper-slide {
        &:first-of-type {
          .pos-ai {
            background: var(--background-cover) url(${ai1});
          }
        }

        &:nth-of-type(2) {
          .pos-ai {
            background: var(--background-cover) url(${ai2});
          }
        }

        &:last-of-type {
          .pos-ai {
            background: var(--background-cover) url(${ai3});
          }
        }

        .pos-ai {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 3rem;
          aspect-ratio: 3 / 4;
          padding: 6rem 4rem;
          color: #fff;
          border-radius: 1.5rem;
          overflow: hidden;
          box-shadow: none;
          transition:
            box-shadow 0.5s ease-in-out,
            transform 0.5s ease-in-out;

          &::before {
            content: '';
            position: absolute;
            inset: 0;
            background-color: #0a0a0a33;
          }

          h5 {
            font-size: 2.8rem;
            font-weight: 700;
            z-index: 1;
          }

          p {
            font-size: 2rem;
            font-weight: 300;
            z-index: 1;

            b {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    .pos-ai__head {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      margin-bottom: 8rem;

      & > p {
        text-align: start;
      }
    }

    .swiper {
      max-width: 70rem;

      .swiper-wrapper {
        .swiper-slide {
          .pos-ai {
            h5 {
              font-size: 2.4rem;
            }

            p {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }

  @media not ${breakpoints.mobile} {
    .pos-ai {
      @media (hover: hover) {
        &:hover {
          box-shadow: 0 0 0.6rem 0.8rem #1f1f1f26;
          transform: translateY(-6rem);
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    .pos-ai__head {
      gap: 1.5rem;
      margin-bottom: 5rem;

      & > p {
        font-size: 1.6rem;
      }
    }

    .swiper {
      max-width: 35rem;

      .swiper-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        .swiper-slide {
          width: 100% !important;

          .pos-ai {
            aspect-ratio: auto;
            padding: 4rem 3rem;

            h5 {
              font-size: 2.2rem;
            }

            p {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
`;

export const PosBanner = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35rem;
  width: 100%;
  aspect-ratio: 1920 / 405;
  background: no-repeat center 70% / cover url(${posBanner});

  .pos-banner__content {
    width: 100%;
    max-width: var(--max-width);
    transform: translateY(2rem);
    word-break: keep-all;
    text-wrap: balance;

    p {
      color: #fff;
      font-size: 4.6rem;

      strong {
        color: #00f78e;
        font-weight: 700;
      }
    }
  }

  @media ${breakpoints.tablet} {
    aspect-ratio: 390 / 188;
    background: var(--background-cover) url(${posBannerMobile});
    min-height: 15rem;
    max-height: 25rem;

    .pos-banner__content {
      text-align: center;
      transform: none;

      p {
        font-size: 3.6rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    max-height: 20rem;

    .pos-banner__content {
      p {
        font-size: 2.2rem;

        strong {
          font-size: 2.4rem;
        }
      }
    }
  }
`;

export const PosSoftware = styled.div`
  width: 100%;
  max-width: var(--max-width);

  .pos-software {
    word-break: keep-all;
    text-wrap: pretty;

    h5 {
      font-size: 3.2rem;
      margin-bottom: 2rem;

      b {
        display: block;
        font-weight: 700;
      }
    }

    .pos-software__grid {
      display: grid;
      grid-template-columns: 2fr 1.3fr;
      gap: 3rem;

      & > div {
        padding: 3rem;
        background-color: #f0f4fa;
        border-radius: 1.5rem;

        &.pos-software__main {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5rem;
          padding-bottom: 0;

          .pos-software__main__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 3rem;
            width: 100%;
            padding-right: 2rem;

            & > div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              img {
                height: 3.5rem;
                width: auto;
              }

              p {
                font-size: 2.6rem;
                font-weight: 600;
                line-height: 1.2;
              }
            }

            p {
              font-size: 1.8rem;
            }
          }

          & > img {
            width: 90%;
          }
        }

        &.pos-software__description {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 1rem;

          & > div {
            position: relative;
            padding-left: 4rem;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              height: 2.5rem;
              width: 2rem;
              background: no-repeat center / contain url(${softwareCheck});
            }

            p {
              font-size: 1.8rem;
              font-weight: 500;

              small {
                color: var(--color-text-light);
                font-size: 1.6rem;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    &:nth-child(even) {
      h5 {
        text-align: end;
      }
    }

    &:not(:last-child) {
      margin-bottom: 15rem;
    }
  }

  @media not ${breakpoints.tablet} {
    .pos-software {
      &:nth-child(even) {
        .pos-software__grid {
          grid-template-columns: 1.3fr 2fr;

          .pos-software__main {
            order: 2;

            .pos-software__main__content {
              align-items: flex-end;
              text-align: end;
              padding-right: 0;
              padding-left: 2rem;

              & > div {
                align-items: flex-end;
              }
            }
          }

          .pos-software__description {
            order: 1;
          }
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    --max-width: 75rem;

    .pos-software {
      .pos-software__grid {
        grid-template-columns: 1fr;
      }
    }
  }

  @media ${breakpoints.mobile} {
    .pos-software {
      h5 {
        font-size: 2.2rem;
        margin-bottom: 2rem;

        b {
          display: inline;
        }
      }

      .pos-software__grid {
        gap: 2rem;

        & > div {
          padding: 2rem;
          border-radius: 1rem;

          &.pos-software__main {
            gap: 4rem;

            .pos-software__main__content {
              gap: 1.5rem;
              padding-right: 1rem;

              & > div {
                img {
                  height: 2.5rem;
                }

                p {
                  font-size: 1.8rem;
                }
              }

              p {
                font-size: 1.6rem;
              }
            }

            & > img {
              width: 90%;
            }
          }

          &.pos-software__description {
            & > div {
              padding-left: 2.5rem;

              &::before {
                height: 2rem;
                width: 1.5rem;
              }

              p {
                font-size: 1.6rem;

                small {
                  font-size: 1.4rem;
                }
              }
            }
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 8rem;
      }
    }
  }
`;

export const PosBusinessArea = styled.div`
  display: flex;
  width: 100%;
  background-color: var(--color-grey-bg);

  & > img {
    flex: 1;
    max-width: 40%;
    object-fit: cover;
  }

  .business-area__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
    padding: 4rem 10rem;

    .business-area__content__head {
      h5 {
        font-size: 4.5rem;
        font-weight: 500;
      }

      p {
        color: var(--color-text-light);
        font-size: 2.2rem;
      }
    }

    .business-area__list {
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;

      .business-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        min-width: 12rem;

        img {
          height: 8rem;
          width: auto;
        }

        p {
          font-size: 1.8rem;
          font-weight: 500;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    & > img {
      display: none;
    }

    .business-area__content {
      align-items: center;
      gap: 4rem;
      padding: 4rem 8rem;

      .business-area__content__head {
        text-align: center;

        h5 {
          font-size: 4rem;
        }

        p {
          font-size: 2rem;
        }
      }

      .business-area__list {
        justify-content: center;
      }
    }
  }

  @media ${breakpoints.mobile} {
    display: block;

    & > img {
      display: block;
      max-height: 30rem;
      width: 100%;
      max-width: 100%;
    }

    .business-area__content {
      padding: 4rem;

      .business-area__content__head {
        h5 {
          font-size: 3rem;
        }

        p {
          font-size: 1.6rem;
        }
      }

      .business-area__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .business-area {
          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`;
