import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const NoticeListContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);

  .notice__head {
    display: flex;
    justify-content: center;
    padding: 4rem 2rem;
    font-size: 4rem;
    font-weight: 700;
    /* border-bottom: 0.1rem solid var(--color-border); */
  }

  .notice__list {
    width: 100%;
    /* margin-top: 10rem; */
    border-top: 0.2rem solid var(--color-border);
    white-space: nowrap;

    th,
    td {
      padding: 2rem 3rem;
    }

    thead {
      tr {
        background-color: var(--color-grey-bg);
        border-bottom: 0.1rem solid var(--color-border-light);

        th:first-child() {
          width: 5%;
        }

        th:nth-child(2) {
          width: 65%;
        }

        th:nth-child(n + 3):nth-child(-n + 4) {
          width: 15%;
        }
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 0.1rem solid var(--color-border-light);
        }

        td:not(:nth-child(2)) {
          text-align: center;
        }

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    .notice__head {
      font-size: 3rem;
      padding: 2rem;
    }

    .notice__list {
      th,
      td {
        padding: 0;
      }

      thead {
        display: none;
      }

      tbody {
        tr {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 1rem 1.5rem;

          td:first-child {
            display: none;
          }

          td:nth-child(2) {
            width: 100%;
            padding-bottom: 0.2rem;
          }

          td:nth-child(3) {
            height: 1.6rem;
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: 0.1rem solid var(--color-border);
            line-height: 1;
          }

          td:nth-child(n + 3):nth-child(-n + 4) {
            color: #a7a7a7;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
`;
