import { Container, ContainerTitle } from 'styles/container.styles';
import { GreetingContent, GreetingTop } from './styles';
import logo from 'assets/img/logo/retailx/logo.png';
import greeting from 'assets/img/company/greeting/greeting.png';
import greetingMobile from 'assets/img/company/greeting/greeting_mobile.png';
import { breakpoints } from 'styles/breakpoints.styles';

const Greeting = () => {
  return (
    <>
      <Container>
        <ContainerTitle>
          <p>CEO 인사말</p>
        </ContainerTitle>

        <GreetingTop>
          <small>CEO Message</small>

          <p>
            <img src={logo} />는 혁신적인 기술과 서비스를 통해 지속 가능한
            미래사회에 공헌합니다.
          </p>
        </GreetingTop>
      </Container>

      <Container $background='var(--color-grey-bg)' $overflow>
        <GreetingContent>
          <picture>
            <source media={breakpoints.tablet} srcSet={greetingMobile} />
            <img src={greeting} />
          </picture>

          <div className='greeting__content'>
            <h5>
              안녕하세요 <strong>리테일엑스</strong> 홈페이지를 방문해주신
              여러분을 진심으로 환영합니다.
            </h5>

            <p>
              2019년 12월 17일에 설립된 IT 벤처회사로 마트, 슈퍼체인 등 도소매
              업체 및 식당, 배달음식 전문점 등을 대상으로 하는 O4O 서비스 및
              소프트웨어∙하드웨어 공급을 주된 사업으로 영위하고 있습니다.
            </p>
            <p>
              주식회사 리테일엑스는 동네 마트, 슈퍼, 식당 및 배달음식 전문점 등
              우리 주변에서 흔히 볼 수 있는 대표적인 소상공인들과의 진정한 상생
              가치 창조를 최우선 목표로 지향하고 있습니다. 주식회사 리테일엑스는
              지역 및 사회의 소상공인들과의 상생을 통해 새로운 비즈니스 영역을
              개척하는 것에 대하여 무한한 자부심과 책임감을 가지고 있습니다.
            </p>
            <p>
              또한 향후 마주하게 될 새로운 도전과 기회를 기꺼이 맞이할 준비가 돼
              있습니다.
            </p>
            <p>
              주식회사 리테일엑스는 기존 대형 O4O 비즈니스 기업과의 경쟁을 통한
              성장이 아니라 오직 소상공인들과의 상생의 가치 토대 위에 새로운
              성장 동력을 끊임없이 만들어 나갈 것입니다.
            </p>
            <p>감사합니다.</p>
            <p>
              <b>대표이사 {process.env.REACT_APP_CHIEF}</b>
            </p>
          </div>
        </GreetingContent>
      </Container>
    </>
  );
};

export default Greeting;
