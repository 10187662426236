import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const NoticeDetailContainer = styled.div`
  --max-width: 108rem;

  width: 100%;
  max-width: var(--max-width);

  .notice__head {
    padding: 2rem 0;
    border-bottom: 0.1rem solid var(--color-border);

    .notice__title {
      font-size: 2.8rem;
      font-weight: 500;
    }

    .notice__info {
      padding-top: 1rem;
      color: var(--color-text-light);
      text-align: end;
    }
  }

  .notice__content {
    padding: 4rem 0;
  }

  @media ${breakpoints.mobile} {
    .notice__head {
      .notice__title {
        font-size: 2.4rem;
      }

      .notice__info {
        font-size: 1.4rem;
      }
    }
  }
`;
