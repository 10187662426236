import { breakpoints } from 'styles/breakpoints.styles';
import { SubPageBanner, SubPageNav, SubPageTop } from 'styles/subPage.styles';
import companyBanner from 'assets/img/company/banner.png';
import companyBannerMobile from 'assets/img/company/banner_mobile.png';
import { NavLink, Outlet } from 'react-router-dom';
import ContactBanner from 'components/ContactBanner';

const Company = () => {
  return (
    <>
      <SubPageTop>
        <SubPageBanner>
          <picture>
            <source media={breakpoints.mobile} srcSet={companyBannerMobile} />
            <img src={companyBanner} />
          </picture>

          <div className='banner__content'>
            <p>회사소개</p>
          </div>
        </SubPageBanner>

        <SubPageNav>
          <ul>
            <li>
              <NavLink to='/company' end>
                회사개요
              </NavLink>
            </li>
            <li>
              <NavLink to='greeting'>CEO 인사말</NavLink>
            </li>
            <li>
              <NavLink to='history'>연혁</NavLink>
            </li>
            <li>
              <NavLink to='map'>오시는 길</NavLink>
            </li>
          </ul>
        </SubPageNav>
      </SubPageTop>

      <Outlet />

      <ContactBanner type='blue' />
    </>
  );
};

export default Company;
