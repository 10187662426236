import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  padding: 0 4rem;
  color: var(--color-text-footer);
  background-color: var(--color-footer-bg);

  .footer__wrapper {
    flex: 1;
    max-width: var(--max-width);

    .footer__menu {
      display: flex;
      align-items: center;
      column-gap: 3rem;
      row-gap: 0.5rem;
      flex-wrap: wrap;
      padding: 3rem 0;
      border-bottom: 0.1rem solid #dfdfdf;

      li {
        a {
          b {
            font-weight: 600;
          }
        }
      }
    }

    .footer__bottom {
      display: flex;
      align-items: start;
      gap: 4rem;
      padding: 4rem 0;

      .footer__info {
        flex: 1;

        .footer__info__name {
          margin-bottom: 3rem;
          font-size: 1.8rem;
          font-weight: 800;
        }

        .footer__info__wrapper {
          display: flex;
          align-items: center;
          column-gap: 2rem;
          row-gap: 0.4rem;
          flex-wrap: wrap;
          max-width: 60rem;
          color: var(--color-text-footer-light);

          p {
            max-width: 100%;
            text-wrap: pretty;
          }

          /* p {
            display: flex;
            align-items: inherit;
            gap: inherit;
  
            &:not(:last-child) {
              &::after {
                content: '';
                display: block;
                height: 1.6rem;
                width: 0.1rem;
                background-color: var(--color-text-footer-light);
              }
            }
          } */
        }

        .footer__copyright {
          margin-top: 0.5rem;
          color: var(--color-text-footer-light);
          font-weight: 500;
        }
      }

      .footer__family {
        display: flex;
        align-items: center;
        gap: 2rem;

        .footer__family__logo {
          height: 2.5rem;
        }

        .footer__family__select {
          --select-color: #888;
          --select-background-color: #222;

          .footer__family__select__control {
            padding: 0.5rem 1rem;
            min-width: 22rem;
            border-color: #dfdfdf;
            border-radius: 1rem;

            .footer__family__select__placeholder {
              color: var(--select-color);
            }

            .footer__family__select__indicator-separator {
              display: none;
            }

            .footer__family__select__indicator {
              color: var(--select-color);
            }

            &:hover,
            &--is-focused {
              border-color: #dfdfdf;
              box-shadow: none;
            }

            &--menu-is-open {
              --select-color: #fff;

              background-color: var(--select-background-color);
              border-color: var(--select-background-color) !important;
              box-shadow: none;

              .footer__family__select__placeholder {
                color: var(--select-color);
              }

              .footer__family__select__indicator {
                color: var(--select-color);
                transform: rotateZ(180deg);
              }
            }
          }

          .footer__family__select__menu {
            --select-color: #fff;

            background-color: var(--select-background-color);
            border-radius: 1rem;

            .footer__family__select__menu-list {
              padding: 1rem 0;

              .footer__family__select__option {
                padding: 1rem 2rem;
                color: var(--select-color);

                &--is-focused {
                  background-color: #ffffff10;
                }
              }
            }
          }
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    padding: 0 2rem;

    .footer__wrapper {
      .footer__menu {
        display: flex;
        justify-content: center;
        column-gap: 2rem;
        padding: 3rem 0 1.5rem;
        font-size: 1.4rem;
      }

      .footer__bottom {
        flex-direction: column;
        gap: 2rem;
        padding: 3rem 0;

        .footer__info {
          font-size: 1.4rem;

          .footer__info__name {
            margin-bottom: 0.5rem;
            font-size: 1.6rem;
          }
        }

        .footer__family {
          width: 100%;
          flex-wrap: wrap;
          row-gap: 1rem;

          .footer__family__logo {
            height: 2rem;
          }

          .footer__family__select {
            flex: 1;
            font-size: 1.4rem;

            .footer__family__select__control {
              padding: 0.2rem 0.5rem;
            }

            .footer__family__select__menu {
              .footer__family__select__menu-list {
                padding: 0.5rem 0;

                .footer__family__select__option {
                  padding: 0.8rem 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;
