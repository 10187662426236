import { Link } from 'react-router-dom';
import { ContactBannerContainer, ContactBannerTypes } from './styles';
import { FC } from 'react';

const ContactBanner: FC<{ type: ContactBannerTypes }> = ({ type }) => {
  return (
    <ContactBannerContainer $type={type}>
      <div className='contact-banner'>
        <div className='contact-banner__content'>
          {type === 'green' ? (
            <>
              <p>30년 구력의 POS 산업의 선두주자</p>
              <p>
                <b>리테일엑스 자세히 알고 싶다면</b>
              </p>
            </>
          ) : (
            <>
              <small>Contact Us</small>
              <p>
                <b>고객문의</b>
              </p>
              <p>솔루션 및 제품문의를 남겨주시면 상세하게 답변드리겠습니다.</p>
            </>
          )}
        </div>

        <Link to='/contact'>
          {type === 'green' ? '바로 문의하기' : '문의하기'}
        </Link>
      </div>
    </ContactBannerContainer>
  );
};

export default ContactBanner;
