import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const CrmKnowHow = styled.div`
  width: 100%;
  max-width: var(--max-width);

  .crm-knowhow {
    display: flex;
    align-items: flex-start;
    gap: 4rem;

    .crm-knowhow__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      padding: 0 2rem;
      word-break: keep-all;
      text-wrap: pretty;

      small {
        position: relative;
        width: fit-content;
        color: var(--color-text-light);
        font-weight: 600;
        white-space: nowrap;

        &::after {
          content: '';
          position: absolute;
          bottom: -0.2rem;
          left: 0;
          height: 0.4rem;
          width: 100%;
          background-color: #ffd0d0;
        }
      }

      p {
        font-size: 2.6rem;
        font-weight: 500;
      }
    }

    img {
      width: 70%;
      border-radius: 3rem;
    }

    &:not(:last-child) {
      margin-bottom: 10rem;
    }

    &:nth-child(even) {
      .crm-knowhow__content {
        align-items: flex-end;
        text-align: end;
        order: 2;
      }

      img {
        order: 1;
      }
    }
  }

  @media ${breakpoints.tablet} {
    .crm-knowhow {
      flex-direction: column;

      .crm-knowhow__content {
        padding: 0;
        order: 1 !important;

        p {
          font-size: 2.2rem;
        }
      }

      img {
        width: 100%;
        border-radius: 2rem;
        order: 2 !important;
      }

      &:not(:last-child) {
        margin-bottom: 8rem;
      }

      &:nth-child(even) {
        align-items: flex-end;
      }
    }
  }

  @media ${breakpoints.mobile} {
    .crm-knowhow {
      gap: 2rem;

      .crm-knowhow__content {
        gap: 1rem;

        small {
          font-size: 1.4rem;
        }

        p {
          font-size: 1.8rem;
        }
      }

      img {
        border-radius: 1rem;
      }

      &:not(:last-child) {
        margin-bottom: 5rem;
      }
    }
  }
`;

export const CrmProcess = styled.div`
  width: 100%;
  max-width: var(--max-width);

  picture {
    width: 100%;

    img {
      width: 100%;
    }
  }

  @media ${breakpoints.mobile} {
    picture {
      max-width: 35rem;
      margin: 0 auto;
    }
  }
`;
