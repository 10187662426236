import { Link } from 'react-router-dom';
import { NotFoundWrapper } from './styles';
import { Container } from 'styles/container.styles';

const NotFound = () => {
  return (
    <>
      <Container>
        <NotFoundWrapper>
          <p className='not-found__code'>404</p>
          <p className='not-found__text'>페이지를 찾을 수 없습니다</p>

          <Link to='/'>메인 페이지로 돌아가기</Link>
        </NotFoundWrapper>
      </Container>
    </>
  );
};

export default NotFound;
