import { breakpoints } from 'styles/breakpoints.styles';
import { SubPageBanner, SubPageNav, SubPageTop } from 'styles/subPage.styles';
import logoWhite from 'assets/img/logo/retailx/logo_w.png';
import productBanner from 'assets/img/product/banner.png';
import productBannerMobile from 'assets/img/product/banner_mobile.png';
import { NavLink, Outlet } from 'react-router-dom';
import ContactBanner from 'components/ContactBanner';

const Product = () => {
  return (
    <>
      <SubPageTop>
        <SubPageBanner>
          <picture>
            <source media={breakpoints.mobile} srcSet={productBannerMobile} />
            <img src={productBanner} />
          </picture>

          <div className='banner__content'>
            <small>
              <img src={logoWhite} />
            </small>
            <p>Technology</p>
          </div>
        </SubPageBanner>

        <SubPageNav>
          <ul>
            <li>
              <NavLink to='/product' end>
                POS 시스템
              </NavLink>
            </li>
            <li>
              <NavLink to='kiosk'>KIOSK</NavLink>
            </li>
            <li>
              <NavLink to='manless'>무인매장 원격관리</NavLink>
            </li>
            <li>
              <NavLink to='app'>쇼핑몰 APP빌더</NavLink>
            </li>
            <li>
              <NavLink to='crm'>CRM 플랫폼</NavLink>
            </li>
          </ul>
        </SubPageNav>
      </SubPageTop>

      <Outlet />

      <ContactBanner type='blue' />
    </>
  );
};

export default Product;
