import { Container, ContainerTitle } from 'styles/container.styles';
import { HistoryBanner, HistoryList } from './styles';
import history202006 from 'assets/img/company/history/202006.png';
import history202009 from 'assets/img/company/history/202009.png';
import history202104 from 'assets/img/company/history/202104.png';

const History = () => {
  return (
    <>
      <Container $type='fullWidth'>
        <ContainerTitle>
          <p>연혁</p>
        </ContainerTitle>

        <HistoryBanner>
          <div className='history__banner'>
            <div className='history__banner__top'>
              <small>HISTORY</small>
              <p>
                POS 산업의 독보적인
                <br />
                기술력과 전문성으로 고객 맞춤 서비스를 실현합니다.
              </p>
            </div>

            <div className='history__banner__bottom'>
              <p>SINCE 2019</p>
            </div>
          </div>
        </HistoryBanner>
      </Container>

      <Container $overflow>
        <HistoryList>
          <div className='history'>
            <div className='history__left'>
              <p className='history__year'>2021</p>
            </div>
            <div className='history__right'>
              <div className='history__row'>
                <p className='history__month'>04</p>
                <div className='history__content'>
                  <p>
                    대정 농협 하나로마트 업무협약 체결 (지역화폐 결제가 가능한
                    O4O플랫폼 도입)
                  </p>

                  <img src={history202104} />
                </div>
              </div>

              <div className='history__row'>
                <p className='history__month'>03</p>
                <div className='history__content'>
                  <p>
                    본사 이전 (부천 원미동{' '}
                    <i className='fal fa-long-arrow-right'></i> 부천 상동)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='history'>
            <div className='history__left'>
              <p className='history__year'>2020</p>
            </div>
            <div className='history__right'>
              <div className='history__row'>
                <p className='history__month'>12</p>
                <div className='history__content'>
                  <p>
                    2020년도 전경련 자문 우수 사례 선정
                    <br />
                    <small>
                      (2020년도 전경련경영자문단 중소기업자문 우수사례집)
                    </small>
                  </p>
                </div>
              </div>

              <div className='history__row'>
                <p className='history__month'></p>
                <div className='history__content'>
                  <p>
                    중소벤처기업부 정부사업 수행기관 선정 (O4O 기반의 스마트
                    오더 및 지역화폐 결제가 지원되는 스마트 POS 및 매장 플랫폼
                    개발)
                  </p>
                </div>
              </div>

              <div className='history__row'>
                <p className='history__month'>09</p>
                <div className='history__content'>
                  <p>
                    기술보증기금 중소기업 R&D 기술평가 보증 승인 (소상공인을
                    위한 O2O 플랫폼 부문, 1억원)
                  </p>

                  <img src={history202009} />
                </div>
              </div>

              <div className='history__row'>
                <p className='history__month'>08</p>
                <div className='history__content'>
                  <p>
                    중소벤처기업부 2020년도 소상공인 자영업자를 위한 생활혁신형
                    기술개발사업 BM개발과제 선정(O4O 기반의 스마트 오더 및
                    지역화폐 결제가 지원되는 스마트 POS 및 매장 플랫폼 개발)
                  </p>
                </div>
              </div>

              <div className='history__row'>
                <p className='history__month'>06</p>
                <div className='history__content'>
                  <p>한국거래소 KSM 등록 승인(매매 개시일 : 2020.06.08)</p>
                  <img src={history202006} />
                </div>
              </div>

              <div className='history__row'>
                <p className='history__month'>04</p>
                <div className='history__content'>
                  <p>투자플랫폼 와디즈 투자형 펀딩 목표 금액 초과 달성(102%)</p>
                </div>
              </div>

              <div className='history__row'>
                <p className='history__month'>01</p>
                <div className='history__content'>
                  <p>
                    본사 이전 (서울 <i className='fal fa-long-arrow-right'></i>{' '}
                    부천 원미동)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='history'>
            <div className='history__left'>
              <p className='history__year'>2019</p>
            </div>
            <div className='history__right'>
              <div className='history__row'>
                <p className='history__month'>12</p>
                <div className='history__content'>
                  <p>법인설립</p>
                </div>
              </div>
            </div>
          </div>
        </HistoryList>
      </Container>
    </>
  );
};

export default History;
