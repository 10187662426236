import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const OverviewContent = styled.div`
  max-width: var(--max-width);
  font-size: 1.8rem;
  word-break: keep-all;
  text-wrap: balance;

  small {
    display: block;
    margin-bottom: 2rem;
    color: var(--color-primary);
    font-weight: 700;
  }

  p {
    color: var(--color-text-light);

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    b {
      color: var(--color-text);
      font-size: 5.4rem;
      font-weight: 500;
      line-height: 1.2;

      strong {
        color: var(--color-primary);
        font-weight: 700;
      }

      img {
        display: inline-block;
        height: 1em;
      }
    }
  }

  @media ${breakpoints.tablet} {
    font-size: 1.6rem;

    small {
      margin-bottom: 1.5rem;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      b {
        font-size: 4.5rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    font-size: 1.6rem;

    small {
      margin-bottom: 1rem;
      font-size: 1.4rem;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      b {
        font-size: 3rem;
      }
    }
  }
`;

export const OverviewLabel = styled.p`
  font-size: 3.6rem;
  font-weight: 600;
  margin-bottom: 7rem;

  @media ${breakpoints.tablet} {
    font-size: 3.2rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoints.mobile} {
    font-size: 2.8rem;
    margin-bottom: 4rem;
    text-align: center;
  }
`;

export const OverviewSummary = styled.div`
  width: 100%;
  max-width: var(--max-width);

  .summary__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: 3rem;
    width: 100%;

    .summary {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 3rem;
      border: 0.1rem solid var(--color-border);
      word-break: keep-all;
      text-wrap: balance;

      .summary__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        p {
          color: var(--color-text-light);
          font-weight: 500;
        }

        img {
          height: 6rem;
          aspect-ratio: 1;
        }
      }

      .summary__value {
        flex: 1;
        display: flex;
        align-items: flex-end;
        font-size: 2.4rem;
        font-weight: 600;

        small {
          width: 100%;
          font-size: 1.8rem;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    .summary__grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      .summary {
        padding: 3rem 2rem;

        .summary__head {
          img {
            height: 5rem;
          }
        }

        .summary__value {
          font-size: 2.2rem;
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    .summary__grid {
      grid-template-columns: 1fr;

      .summary {
        padding: 2rem;

        .summary__head {
          p {
            font-size: 1.4rem;
          }

          img {
            height: 4rem;
          }
        }

        .summary__value {
          font-size: 1.8rem;

          small {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`;

export const OverviewCoreValue = styled.div`
  width: 100%;
  max-width: var(--max-width);

  .swiper {
    overflow: visible;

    .core-value {
      position: relative;
      display: flex;
      align-items: flex-end;
      aspect-ratio: 63 / 38;
      padding: 12% 8%;

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .core-value__content {
        color: #fff;
        font-size: 3.6rem;
        font-weight: 700;
        z-index: 1;

        img {
          height: 6rem;
          aspect-ratio: 1;
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    .swiper {
      .core-value {
        padding: 10% 8%;

        .core-value__content {
          font-size: 3.2rem;

          img {
            height: 5.5rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    .swiper {
      .core-value {
        padding: 8%;

        .core-value__content {
          font-size: 2.8rem;

          img {
            height: 5rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
