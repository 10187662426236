import styled from 'styled-components';

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 6rem 0;

  img {
    height: 12rem;
    width: auto;
    margin-bottom: 2rem;
    opacity: 0.6;
  }

  .not-found__code {
    color: var(--color-primary);
    font-family: var(--font-gongGothic);
    font-size: 3rem;
    font-weight: 700;
  }

  .not-found__text {
    color: #8f8f8f;
    font-weight: 300;
    margin-bottom: 2rem;
  }
`;
