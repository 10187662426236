import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import RootLayout from 'layouts/RootLayout/indext';
import Main from 'pages/Main';
import NotFound from 'pages/NotFound';
import Company from 'pages/Company';
import Product from 'pages/Product';
import { Container } from 'styles/container.styles';
import Contact from 'pages/Contact';
import Overview from 'pages/Company/Overview';
import Greeting from 'pages/Company/Greeting';
import History from 'pages/Company/History';
import Map from 'pages/Company/Map';
import Pos from 'pages/Product/Pos';
import Kiosk from 'pages/Product/Kiosk';
import Manless from 'pages/Product/Manless';
import App from 'pages/Product/App';
import Crm from 'pages/Product/Crm';
import Notice from 'pages/Notice';
import NoticeList from 'pages/Notice/List';
import NoticeDetail from 'pages/Notice/Detail';
import Privacy from 'pages/Privacy';

const Router = () => {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <RootLayout />,
      children: [
        { index: true, element: <Main /> },
        {
          path: 'company',
          element: <Company />,
          children: [
            {
              index: true,
              element: <Overview />,
            },
            {
              path: 'greeting',
              element: <Greeting />,
            },
            {
              path: 'history',
              element: <History />,
            },
            {
              path: 'map',
              element: <Map />,
            },
          ],
        },
        {
          path: 'product',
          element: <Product />,
          children: [
            {
              index: true,
              element: <Pos />,
            },
            {
              path: 'kiosk',
              element: <Kiosk />,
            },
            {
              path: 'manless',
              element: <Manless />,
            },
            {
              path: 'app',
              element: <App />,
            },
            {
              path: 'crm',
              element: <Crm />,
            },
          ],
        },
        { path: 'contact', element: <Contact /> },
        {
          path: 'notice',
          element: <Notice />,
          children: [
            { index: true, element: <NoticeList /> },
            { path: ':id', element: <NoticeDetail /> },
          ],
        },
        {
          path: 'privacy',
          element: <Privacy />,
        },
      ],
    },
    { path: '*', element: <NotFound /> },
  ];

  const browserRouter = createBrowserRouter(routes);

  return (
    <Suspense>
      <RouterProvider router={browserRouter} />
    </Suspense>
  );
};

export default Router;
