import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const ManlessSystem = styled.div`
  width: 100%;
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    padding: 5rem;
    background-color: #f7f7f7;
    word-break: keep-all;
    text-wrap: pretty;

    &:nth-child(even) {
      background-color: #242424;
    }

    &.manless-system__store {
      p {
        font-size: 3.2rem;
        font-weight: 300;

        strong {
          color: var(--color-primary);
          font-weight: 700;
        }
      }

      img {
        width: 100%;
      }
    }

    &.manless-system__monitoring {
      color: #fff;
      align-items: center;
      text-align: center;

      img {
        width: 80%;
      }

      p {
        font-size: 2.8rem;
        font-weight: 700;

        strong {
          color: #00f78e;
        }
      }

      small {
        font-size: 2rem;
      }
    }
  }

  @media ${breakpoints.tablet} {
    & > div {
      gap: 3rem;
      padding: 4rem;

      &.manless-system__store {
        p {
          font-size: 2.6rem;
        }
      }

      &.manless-system__monitoring {
        p {
          width: 100%;
          font-size: 2.4rem;
        }

        small {
          font-size: 1.8rem;
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;

    & > div {
      padding: 8rem 2rem;

      &.manless-system__store {
        gap: 5rem;
        align-items: center;
        text-align: center;

        p {
          font-size: 2.2rem;
        }
      }

      &.manless-system__monitoring {
        gap: 2rem;
        text-align: start;

        p {
          font-size: 2.2rem;
          order: 1;
        }

        small {
          font-size: 1.6rem;
          order: 2;
        }

        img {
          order: 3;
        }
      }
    }
  }
`;

export const ManlessFunction = styled.div`
  --max-width: 96rem;
  --color-primary: #242424;

  width: 100%;
  max-width: var(--max-width);

  .swiper {
    overflow: visible;

    .swiper-pagination {
      bottom: -4rem;

      .swiper-pagination-bullet-active {
        background: var(--color-primary);
        border-color: var(--color-primary);
      }
    }

    .swiper-wrapper {
      align-items: stretch;

      .swiper-slide {
        height: auto;

        div {
          height: 100%;

          .manless-function {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 12rem;
            padding: 4rem 2rem;
            border: 0.1rem solid var(--color-border);
            border-radius: 1rem;
            word-break: keep-all;
            text-wrap: pretty;
            transition:
              color 0.3s ease-in-out,
              background-color 0.3s ease-in-out;

            p {
              font-size: 2.4rem;
              font-weight: 600;
            }

            img {
              align-self: flex-end;
              height: 8rem;
              transition: opacity 0.3s ease-in-out;

              &.active {
                position: absolute;
                bottom: 4rem;
                right: 2rem;
                opacity: 0;
              }
            }

            @media (hover: hover) {
              &:hover {
                color: #fff;
                background-color: var(--color-primary);

                img {
                  opacity: 0;

                  &.active {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media not ${breakpoints.tablet} {
    .swiper {
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        gap: 3rem;

        .swiper-slide {
          width: 100% !important;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          width: 30rem;
          aspect-ratio: 3 / 4;

          .manless-function {
            gap: 0;

            p {
              font-size: 2.2rem;
            }

            img {
              height: 7rem;
            }
          }
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          width: 25rem;

          .manless-function {
            p {
              font-size: 2rem;
            }

            img {
              height: 6.5rem;
            }
          }
        }
      }
    }
  }
`;
