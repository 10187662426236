import { useEffect, useRef } from 'react';
import { Container, ContainerTitle } from 'styles/container.styles';
import { MapContainer, MapInfo } from './styles';
import { Map as KakaoMap, MapMarker } from 'react-kakao-maps-sdk';
import mapAddress from 'assets/img/company/map/map_address.png';
import mapTel from 'assets/img/company/map/map_tel.png';
import mapFax from 'assets/img/company/map/map_fax.png';
import mapMail from 'assets/img/company/map/map_mail.png';
import {
  kakaoMapCenter,
  kakaoMapStyles,
  mapCenter,
} from 'config/kakaoMap.config';

const Map = () => {
  const kakaoMap = useRef<kakao.maps.Map>(null);

  useEffect(() => {
    const resizeMap = () => {
      if (kakaoMap.current) {
        kakaoMap.current.setCenter(mapCenter);
      }
    };

    window.addEventListener('resize', resizeMap);

    return () => {
      window.removeEventListener('resize', resizeMap);
    };
  }, []);

  return (
    <Container>
      <ContainerTitle>
        <p>오시는 길</p>
      </ContainerTitle>

      <MapContainer>
        <KakaoMap ref={kakaoMap} center={kakaoMapCenter} style={kakaoMapStyles}>
          <MapMarker position={kakaoMapCenter}></MapMarker>
        </KakaoMap>
      </MapContainer>

      <MapInfo>
        <p className='map-info__title'>{process.env.REACT_APP_COMPANY}</p>

        <div className='map-info__list'>
          <div className='map-info'>
            <img src={mapAddress} />
            <p>
              <b>ADDRESS.</b>
            </p>
            <p>{process.env.REACT_APP_COMPANY_ADDRESS}</p>
          </div>

          <div className='map-info'>
            <img src={mapTel} />
            <p>
              <b>TEL.</b>
            </p>
            <p>{process.env.REACT_APP_COMPANY_TEL}</p>
          </div>

          <div className='map-info'>
            <img src={mapFax} />
            <p>
              <b>FAX.</b>
            </p>
            <p>{process.env.REACT_APP_COMPANY_FAX}</p>
          </div>

          <div className='map-info'>
            <img src={mapMail} />
            <p>
              <b>E-MAIL.</b>
            </p>
            <p>{process.env.REACT_APP_COMPANY_EMAIL}</p>
          </div>
        </div>
      </MapInfo>
    </Container>
  );
};

export default Map;
