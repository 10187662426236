import styled from 'styled-components';

export const Btn = styled.button.attrs({ type: 'button' })`
  &:not(:disabled) {
    &:active {
      filter: brightness(0.9);
    }
  }

  &:disabled {
    cursor: default;
  }
`;
