import styled from 'styled-components';
import appWebBg from 'assets/img/product/app/app_web.png';
import appMobileBg from 'assets/img/product/app/app_mobile.png';
import appNeonBg from 'assets/img/product/app/app_neon.png';
import { breakpoints } from 'styles/breakpoints.styles';

export const AppGrid = styled.div`
  --max-width: 110rem;

  display: grid;
  grid-template-columns: 1fr 0.05fr 0.8fr;
  gap: 1.5rem;
  width: 100%;
  max-width: var(--max-width);

  .app__content {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    min-height: 32rem;
    padding: 4rem 3rem;
    font-weight: 300;
    word-break: keep-all;
    text-wrap: pretty;

    &.app__content--span-2 {
      grid-column: span 2;
    }

    &.app__content--span-3 {
      grid-column: span 3;
    }

    &.app__content--grey {
      background-color: var(--color-grey-bg);
    }

    &.app__content--dark {
      color: #fff;
      background-color: #0c0b0e;
    }

    &.app__content--web {
      background: var(--background-cover) url(${appWebBg});
    }

    &.app__content--mobile {
      background: var(--background-cover) url(${appMobileBg});
    }

    &.app__content--neon {
      background:
        no-repeat bottom -8rem right -6rem / 27rem url(${appNeonBg}),
        #0c0b0e;
    }

    &:first-child {
      b {
        white-space: nowrap;
      }
    }

    &:last-child {
      flex-direction: row;
      align-items: center;
      gap: 5rem;
      min-height: auto;
      padding: 6rem;

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 3rem;
      }

      img {
        width: 30rem;
        padding: 0 1rem;
      }
    }

    strong {
      background-image: linear-gradient(91deg, #00f78e, #65b2ff);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      font-size: 2.8rem;
      line-height: 1.4;
    }

    em {
      font-size: 3.2rem;
      font-weight: 500;
    }

    small {
      font-size: 1.8rem;
      line-height: 1.8;

      sub {
        color: var(--color-text-light);
        font-size: 1.6rem;
        vertical-align: middle;
      }
    }

    b {
      font-weight: 700;
    }
  }

  @media ${breakpoints.tablet} {
    .app__content {
      padding: 4rem 2rem;

      &:last-child {
        img {
          display: none;
        }
      }

      p {
        font-size: 2.4rem;
      }

      em {
        font-size: 2.6rem;
      }

      small {
        font-size: 1.6rem;

        sub {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    --max-width: 50rem;

    grid-template-columns: 1fr;

    .app__content {
      grid-column: span 1 !important;
      gap: 2rem;
      min-height: 20rem !important;
      padding: 3rem 2rem !important;
      order: 3;

      &.app__content--neon {
        background:
          no-repeat bottom -5rem right -4rem / 18rem url(${appNeonBg}),
          #0c0b0e;
        order: 1;

        strong {
          display: block;
        }
      }

      &:last-child {
        div {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 2rem;

          small {
            line-height: 2;
          }
        }
      }

      p {
        font-size: 2.2rem;
      }

      em {
        font-size: 2.2rem;
      }
    }
  }
`;
