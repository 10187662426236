import { Container, ContainerTitle } from 'styles/container.styles';
import { AppGrid } from './styles';
import appMarketing from 'assets/img/product/app/app_marketing.png';

const App = () => {
  return (
    <Container>
      <ContainerTitle data-aos='fade-up'>
        <p>
          쇼핑몰 <strong>APP 빌더</strong>
        </p>
      </ContainerTitle>

      <AppGrid>
        <div className='app__content app__content--grey' data-aos='fade-right'>
          <p>
            나만의 <b>「 독립적인 쇼핑몰 」</b> 운영을 위한 통합 솔루션 제공
          </p>

          <small>
            자체 사이트 플레이스토어/ 앱스토어 등록
            <br />
            독자적으로 검색엔진 등록 광고 및 홍보 (별도 도메인 등록)
            <br />
            매장 자체 상점로고 사용
          </small>
        </div>

        <div
          className='app__content app__content--span-2 app__content--dark app__content--neon'
          data-aos='fade-left'
        >
          <small>
            <strong>쇼핑몰 APP Builder</strong>
          </small>

          <p>
            <em>
              쇼핑몰 개설 및 홍보를 위한 <strong>올인원 플랫폼</strong>
            </em>
          </p>
        </div>

        <div
          className='app__content app__content--span-2 app__content--web'
          data-aos='fade-up'
        ></div>

        <div
          className='app__content app__content--grey'
          data-aos='fade-up'
          data-aos-delay='200'
        >
          <p>
            맞춤형 홍보로 <b>자체 브랜드 강화</b> 및 고객소통을 위한 혁신 솔루션
          </p>

          <small>
            자체 배너 및 상품 카테고리 운영
            <br />
            매장의 내 고객에게만 PUSH 메시지 무제한 발송 가능
          </small>
        </div>

        <div
          className='app__content app__content--span-2 app__content--grey'
          data-aos='fade-right'
        >
          <p>
            광고, 주문, 결제, 배송, 푸쉬알림까지 <b>모바일로 한번에 해결</b>
          </p>

          <small>
            다양한 가격정책으로 개인(B2C) 및 기업고객(B2B) 대상 판매 증대
            <br />
            실시간 푸쉬로 할인서비스 광고 및 판매효과 증대{' '}
            <sub>(깜짝세일, 마감세일, 떨이판매 등)</sub>
            <br />
            쿠폰 발행 기능 <sub>(구매금액별 쿠폰 적용 가능)</sub>
            <br />
            위치 인식 기반으로 고객과 가까운 매장연결으로 판매 증대
            <br />
          </small>
        </div>

        <div
          className='app__content app__content--mobile'
          data-aos='fade-left'
          data-aos-delay='200'
        ></div>

        <div
          className='app__content app__content--span-3 app__content--dark'
          data-aos='fade-up'
        >
          <div>
            <p>
              <em>
                <b>
                  <strong>인플루언서를 활용한 자사 쇼핑몰 마케팅</strong>
                </b>
              </em>
            </p>

            <small>
              <b>유튜브에서 QR 코드를 스캔</b>하면 직접 운영하는 매장으로 연결
              <br />
              <b>블로그, 인플루언서 QR코드 스캔</b>하면 직접 운영하는 매장으로
              연결
            </small>
          </div>

          <img src={appMarketing} />
        </div>
      </AppGrid>
    </Container>
  );
};

export default App;
