import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const MapContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);
  aspect-ratio: 120 / 65;
  margin-bottom: 4rem;

  @media ${breakpoints.mobile} {
    margin-bottom: 2rem;
  }
`;

export const MapInfo = styled.div`
  width: 100%;
  max-width: var(--max-width);
  padding: 6rem 6rem 5rem;
  color: #fff;
  background-color: var(--color-primary);

  .map-info__title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .map-info__list {
    display: flex;
    column-gap: 4rem;
    row-gap: 2rem;
    flex-wrap: wrap;

    .map-info {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      row-gap: 0.5rem;
      flex-wrap: wrap;
      max-width: 100%;

      img {
        height: 2.2rem;
        width: 2.2rem;
      }

      p {
        max-width: 100%;
        font-weight: 300;
        word-break: keep-all;
        text-wrap: balance;

        b {
          font-weight: 700;
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    padding: 4rem 3rem 3rem;

    .map-info__title {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    .map-info__list {
      column-gap: 3rem;
      row-gap: 1rem;

      .map-info {
        img {
          height: 1.8rem;
          width: 1.8rem;
        }

        p {
          font-size: 1.4rem;
        }
      }
    }
  }
`;
