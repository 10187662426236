import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';
import { ContainerTitle } from 'styles/container.styles';

export const MainBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  aspect-ratio: 192 / 84;
  width: 100%;
  min-height: 60rem;
  overflow: hidden;

  @media ${breakpoints.tablet} {
    min-height: 50rem;
  }

  @media ${breakpoints.mobile} {
    align-items: flex-start;
    aspect-ratio: 39 / 63;
    max-height: 100vh;
    min-height: 55rem;
    padding-top: max(25%, 10rem);
  }

  picture {
    position: absolute;
    inset: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .main-banner__content {
    --max-width: 130rem;

    display: flex;
    flex: 1;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 4rem;
    color: #fff;
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.4;
    z-index: 1;

    p {
      width: 100%;
      word-break: keep-all;
      text-wrap: pretty;
    }

    b {
      font-weight: 700;
    }

    @media ${breakpoints.tablet} {
      font-size: 5rem;
    }

    @media ${breakpoints.mobile} {
      padding: 0 2rem;
      font-size: max(7vw, 3rem);
    }
  }
`;

export const AboutMartTong = styled.div`
  display: flex;
  align-items: center;
  gap: min(8rem, 5vw);
  max-width: var(--max-width);

  .about-retailx__content {
    flex: 1;
    word-break: keep-all;
    text-wrap: balance;

    .about-retailx__title {
      padding-bottom: 5rem;
      font-family: var(--font-s-core-dream);
      font-size: 6rem;
      font-weight: 700;
      line-height: 1.4;
      white-space: nowrap;

      p {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
          height: 8rem;
          flex: 0;
        }
      }
    }

    .about-retailx__description {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      color: var(--color-text-light);
      font-size: 1.8rem;
    }
  }

  picture {
    flex: 1;

    img {
      border-radius: 0 6rem 0 6rem;
    }
  }

  @media ${breakpoints.tablet} {
    .about-retailx__content {
      .about-retailx__title {
        padding-bottom: 4rem;
        font-size: 5rem;

        p {
          img {
            height: 7rem;
          }
        }
      }

      .about-retailx__description {
        gap: 2rem;
        font-size: 1.6rem;
      }
    }

    picture {
      img {
        border-radius: 0 5rem 0 5rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    --max-width: 50rem;

    flex-direction: column;
    gap: 5rem;

    .about-retailx__content {
      order: 2;

      .about-retailx__title {
        padding-bottom: 3rem;
        font-size: 4rem;

        p {
          img {
            height: 5rem;
          }
        }
      }
    }

    picture {
      order: 1;

      img {
        border-radius: 0 5rem 0 5rem;
      }
    }
  }
`;

export const CoreValues = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10rem;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  padding: 4rem 0;

  .swiper {
    position: relative;
    display: flex;
    flex: 1;
    padding: 4rem 0;

    .core-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      font-size: 2rem;
      font-weight: 600;

      img {
        height: 8rem;
        width: 8rem;
      }
    }
  }

  @media ${breakpoints.tablet} {
    .swiper {
      .core-value {
        font-size: 1.8rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    --max-width: 50rem;

    flex-direction: column;
    gap: 1rem;
    padding: 0;

    .swiper {
      width: 100%;

      .core-value {
        font-size: 1.6rem;
      }
    }
  }

  @media not ${breakpoints.mobile} {
    ${ContainerTitle} {
      align-items: flex-start;
    }
  }
`;

export const OurClient = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;
  width: 100%;
  max-width: var(--max-width);

  .our-client {
    position: relative;
    border-radius: 3rem;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }

    .our-client__content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 6rem 2rem;
      z-index: 1;

      .our-client__label {
        color: #fff;
        font-size: 3.5rem;
        font-weight: 700;
      }

      .our-client__value {
        color: #00f78e;
        font-size: 6rem;
        font-weight: 600;

        &::after {
          content: ' 개';
          font-size: 3.8rem;
          font-weight: 500;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    gap: 3rem;

    .our-client {
      .our-client__content {
        padding: 5rem 2rem;

        .our-client__label {
          font-size: 2.5rem;
        }

        .our-client__value {
          font-size: 5rem;

          &::after {
            font-size: 3.5rem;
          }
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    --max-width: 50rem;

    grid-template-columns: 1fr;
    gap: 2rem;

    .our-client {
      .our-client__content {
        padding: 4rem 2rem;

        .our-client__label {
          font-size: 2rem;
        }

        .our-client__value {
          font-size: 4rem;

          &::after {
            font-size: 3rem;
          }
        }
      }
    }
  }
`;

export const OnlyMartTong = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10rem;
  width: 100%;
  max-width: var(--max-width);
  word-break: keep-all;
  text-wrap: pretty;

  .only-retailx {
    display: flex;
    align-items: center;
    gap: 10rem;
    max-width: 95rem;

    picture {
      position: relative;
      flex: 1;
      max-width: 40rem;
      border-radius: 1rem;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .only-retailx__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      font-size: 1.8rem;

      p {
        color: var(--color-text-light);
        font-weight: 500;

        &.only-retailx__title {
          color: var(--color-text);
          font-size: 3.8rem;
          font-weight: 600;
          line-height: 1.25;
        }
      }

      a {
        color: var(--color-primary);
        font-weight: 600;

        &::after {
          content: '자세히보기 >';
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    gap: 8rem;

    .only-retailx {
      gap: 8rem;

      .only-retailx__content {
        p {
          &.only-retailx__title {
            font-size: 3.4rem;
          }
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    --max-width: 50rem;

    gap: 10rem;

    .only-retailx {
      flex-direction: column;
      gap: 5rem;

      picture {
        max-width: min(80%, 30rem);
      }

      .only-retailx__content {
        font-size: 1.6rem;

        p {
          &.only-retailx__title {
            font-size: 2.8rem;
          }
        }
      }
    }
  }

  @media not ${breakpoints.mobile} {
    .only-retailx {
      &:nth-child(even) {
        picture {
          order: 2;
        }

        .only-retailx__content {
          order: 1;
        }
      }
    }
  }
`;

export const Expect = styled.div`
  --max-width: 105rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 2rem;
  max-width: var(--max-width);

  ${ContainerTitle} {
    justify-content: center;
    align-items: flex-start;
    text-align: start;
  }

  .expect__card {
    position: relative;
    background-color: transparent;
    perspective: 100rem;

    &:hover {
      .expect__card__inner {
        transform: rotateY(180deg);
      }
    }

    .expect__card__inner {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: transform 0.8s;
      transform-style: preserve-3d;

      .expect {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4rem;
        padding: 5rem 2.5rem 3rem;
        color: #fff;
        background-image: linear-gradient(
          128deg,
          #09653a -2%,
          #07ad67 48%,
          #3a4a40 106%
        );
        border-radius: 1rem;
        word-break: keep-all;
        text-wrap: pretty;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }

        &:last-child {
          color: var(--color-text);
          background-image: none;
          background-color: #fff;
          border: 0.1rem solid #208f3b;
          transform: rotateY(180deg);
        }

        .expect__title {
          font-size: 2.8rem;
          font-weight: 600;
        }

        .expect__description {
          flex: 1;
          font-size: 1.8rem;
          line-height: 1.7;

          b {
            font-weight: bold;
          }
        }

        img {
          align-self: flex-end;
          height: 7rem;
          aspect-ratio: 1;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    --max-width: 70rem;

    grid-template-columns: repeat(2, 1fr);

    .expect__card {
      .expect__card__inner {
        .expect {
          gap: 3rem;
        }
      }
    }
  }

  @media not ${breakpoints.mobile} {
    ${ContainerTitle} {
      width: 80%;
    }
  }

  @media ${breakpoints.mobile} {
    --max-width: 35rem;

    display: flex;
    flex-direction: column;

    ${ContainerTitle} {
      margin-bottom: 2rem;
    }

    .expect__card {
      .expect__card__inner {
        .expect {
          padding-top: 4rem;

          .expect__title {
            font-size: 2.6rem;
          }

          .expect__description {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`;
