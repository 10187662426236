import { useState, useEffect, useCallback } from 'react';
import {
  HeaderContainer,
  HeaderLogo,
  HeaderMobileNavBtn,
  MobileNavCloseBtn,
  MobileNavContainer,
} from './styles';
import logo from 'assets/img/logo/retailx/logo.png';
import logoWhite from 'assets/img/logo/retailx/logo_w.png';
import { useAnimate } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
  const { pathname } = useLocation();
  const [scope, animate] = useAnimate();

  const [isTransparent, setIsTransparent] = useState<boolean>(
    window.scrollY < 20,
  );
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);

  const openMobileNav = useCallback(async () => {
    document.body.classList.add('mobile-scroll-disabled');
    animate('.mobile-nav', { transform: 'translateX(0%)' });
    animate('.mobile-nav__dimmer', {
      display: 'block',
      opacity: 1,
    });
  }, [animate]);

  const closeMobileNav = useCallback(async () => {
    animate('.mobile-nav', { transform: 'translateX(100%)' });
    await animate('.mobile-nav__dimmer', {
      opacity: 0,
    });
    animate('.mobile-nav__dimmer', {
      display: 'none',
    });
    document.body.classList.remove('mobile-scroll-disabled');
  }, [animate]);

  useEffect(() => {
    isMobileNavOpen ? openMobileNav() : closeMobileNav();
  }, [isMobileNavOpen, openMobileNav, closeMobileNav]);

  useEffect(() => {
    setIsMobileNavOpen(false);
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setIsTransparent(window.scrollY < 20);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <HeaderContainer $isTransparent={isTransparent}>
        <HeaderLogo to={'/'}>
          <img src={isTransparent ? logoWhite : logo} alt='리테일엑스' />
        </HeaderLogo>

        <nav>
          <ul className='header__menu'>
            <li>
              <NavLink to='/company'>회사소개</NavLink>
            </li>
            <li>
              <NavLink to='/product'>제품소개</NavLink>
            </li>
            <li>
              <NavLink to='/contact'>고객문의</NavLink>
            </li>
            <li>
              <NavLink to='/notice'>공지사항</NavLink>
            </li>
          </ul>
        </nav>

        <HeaderMobileNavBtn
          onClick={() => {
            setIsMobileNavOpen(true);
          }}
        >
          <i className='far fa-bars'></i>
        </HeaderMobileNavBtn>
      </HeaderContainer>

      <MobileNavContainer ref={scope}>
        <div className='mobile-nav'>
          <div className='mobile-nav__header'>
            <MobileNavCloseBtn>
              <i
                className='fal fa-times'
                onClick={() => {
                  setIsMobileNavOpen(false);
                }}
              ></i>
            </MobileNavCloseBtn>

            <img src={logo} alt='리테일엑스' className='mobile-nav__logo' />
          </div>

          <ul className='mobile-nav__menu'>
            <li>
              <NavLink to='/company'>회사소개</NavLink>
            </li>
            <li>
              <NavLink to='/product'>제품소개</NavLink>
            </li>
            <li>
              <NavLink to='/contact'>고객문의</NavLink>
            </li>
            <li>
              <NavLink to='/notice'>공지사항</NavLink>
            </li>
          </ul>
        </div>

        <div
          className='mobile-nav__dimmer'
          onClick={() => {
            setIsMobileNavOpen(false);
          }}
        ></div>
      </MobileNavContainer>
    </>
  );
};

export default Header;
