import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const KioskGrowth = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  width: 100%;
  max-width: var(--max-width);

  .kiosk-growth__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    word-break: keep-all;
    text-wrap: pretty;

    h5 {
      font-size: 4.5rem;
      font-weight: 700;

      strong {
        color: var(--color-primary);
      }
    }

    p {
      color: var(--color-text-light);
      font-size: 2.2rem;
    }
  }

  img {
    flex: 1;
    max-width: 50%;
  }

  @media ${breakpoints.tablet} {
    gap: 8rem;

    .kiosk-growth__content {
      gap: 2rem;

      h5 {
        font-size: 4rem;
      }

      p {
        font-size: 1.8rem;
      }
    }
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 3rem;

    .kiosk-growth__content {
      gap: 1.5rem;

      h5 {
        font-size: 3.5rem;
      }

      p {
        font-size: 1.6rem;
      }
    }

    img {
      width: 100%;
      max-width: 40rem;
    }
  }
`;

export const KioskAutomation = styled.div`
  --max-width: 110rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8rem;
  width: 100%;
  max-width: var(--max-width);

  img {
    border-radius: 3rem 3rem 0 0;
  }

  @media ${breakpoints.tablet} {
    gap: 6rem;
  }

  @media ${breakpoints.mobile} {
    gap: 5rem;
  }
`;

export const KioskInquiry = styled(KioskAutomation)`
  img {
    max-width: 50rem;
  }
`;

export const KioskMethod = styled(KioskAutomation)`
  --max-width: 124rem;

  .kiosk-method__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    width: 100%;

    .kiosk-method {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 5rem 2rem;
      border: 0.1rem solid var(--color-border);
      border-radius: 1rem;

      img {
        height: 6.5rem;
      }

      p {
        padding: 0.2rem 1.5rem;
        color: var(--color-text-light);
        background-color: #eaeaec;
        font-size: 1.8rem;
        font-weight: 600;
        border-radius: 10rem;
      }
    }
  }

  @media ${breakpoints.tablet} {
    .kiosk-method__list {
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
      gap: 3rem;
    }
  }

  @media ${breakpoints.mobile} {
    .kiosk-method__list {
      gap: 2rem;

      .kiosk-method {
        padding: 4rem 2rem;

        img {
          height: 5rem;
        }

        p {
          font-size: 1.6rem;
        }
      }
    }
  }
`;

export const KioskExpect = styled(KioskAutomation)`
  --max-width: 124rem;

  .kiosk-expect__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;

    .kiosk-expect {
      display: flex;
      align-items: center;
      gap: 4rem;
      padding: 5rem 4rem;
      background:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(291deg, #47f4ab, #155799) border-box;
      border: 0.1rem solid transparent;
      border-radius: 1.5rem;
      word-break: keep-all;
      text-wrap: pretty;

      img {
        height: 7rem;
      }

      .kiosk-expect__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        h5 {
          position: relative;
          width: fit-content;
          font-size: 2.4rem;
          font-weight: 500;

          strong {
            color: var(--color-primary);
            font-weight: 600;
          }

          &::after {
            content: '';
            position: absolute;
            display: block;
            bottom: 0.2rem;
            left: 0;
            height: 1.6rem;
            width: 100%;
            background-color: #07623826;
          }
        }

        p {
          color: var(--color-text-light);
          font-size: 1.8rem;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    --max-width: 80rem;

    .kiosk-expect__list {
      grid-template-columns: 1fr;

      .kiosk-expect {
        img {
          height: 6rem;
        }

        .kiosk-expect__content {
          h5 {
            font-size: 2.2rem;

            &::after {
              bottom: 0.1rem;
            }
          }
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    --max-width: 50rem;

    .kiosk-expect__list {
      .kiosk-expect {
        flex-direction: column-reverse;
        gap: 1rem;
        padding: 3rem 2rem 2rem 2rem;

        img {
          align-self: flex-end;
          height: 5rem;
        }

        .kiosk-expect__content {
          h5 {
            font-size: 1.8rem;

            &::after {
              bottom: 0;
              height: 1.4rem;
            }
          }

          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
`;
