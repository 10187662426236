import { Link } from 'react-router-dom';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import {
  AboutMartTong,
  CoreValues,
  Expect,
  MainBanner,
  OnlyMartTong,
  OurClient,
} from './styles';
import { breakpoints } from 'styles/breakpoints.styles';
import { Container, ContainerTitle } from 'styles/container.styles';
import ContactBanner from 'components/ContactBanner';
import logo from 'assets/img/logo/retailx/logo.png';
import bannerKiosk from 'assets/img/main/banner_kiosk.png';
import bannerKioskMobile from 'assets/img/main/banner_kiosk_mobile.png';
import bannerPos from 'assets/img/main/banner_pos.png';
import bannerPosMobile from 'assets/img/main/banner_pos_mobile.png';
import aboutMartTong from 'assets/img/main/about_retailx.png';
import aboutMartTongMobile from 'assets/img/main/about_retailx_mobile.png';
import coreInnovation from 'assets/img/main/core_innovation.png';
import coreIdea from 'assets/img/main/core_idea.png';
import coreCustomer from 'assets/img/main/core_customer.png';
import coreChallenge from 'assets/img/main/core_challenge.png';
import clientStore from 'assets/img/main/client_store.png';
import clientCompany from 'assets/img/main/client_company.png';
import onlyPos from 'assets/img/main/only_pos.png';
import onlyManless from 'assets/img/main/only_manless.png';
import onlyShoppingMall from 'assets/img/main/only_shoppingmall.png';
import onlyCrm from 'assets/img/main/only_crm.png';
import expectLocal from 'assets/img/main/expect_local.png';
import expectLocalActive from 'assets/img/main/expect_local_w.png';
import expectCompetition from 'assets/img/main/expect_competition.png';
import expectCompetitionActive from 'assets/img/main/expect_competition_w.png';
import expectSoftware from 'assets/img/main/expect_software.png';
import expectSoftwareActive from 'assets/img/main/expect_software_w.png';
import expectSolution from 'assets/img/main/expect_solution.png';
import expectSolutionActive from 'assets/img/main/expect_solution_w.png';
import expectHire from 'assets/img/main/expect_hire.png';
import expectHireActive from 'assets/img/main/expect_hire_w.png';
import CountUp from 'react-countup';

SwiperCore.use([Autoplay]);

const Main = () => {
  return (
    <>
      <Swiper
        loop
        speed={500}
        modules={[Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
      >
        <SwiperSlide>
          <MainBanner>
            <picture>
              <source media={breakpoints.mobile} srcSet={bannerKioskMobile} />
              <img src={bannerKiosk} />
            </picture>
            <div className='main-banner__content'>
              <p>
                리테일엑스는
                <br />
                <b>30년 구력의</b> 포스 사업의
                <br />
                선두주자 입니다.
              </p>
            </div>
          </MainBanner>
        </SwiperSlide>
        <SwiperSlide>
          <MainBanner>
            <picture>
              <source media={breakpoints.mobile} srcSet={bannerPosMobile} />
              <img src={bannerPos} />
            </picture>
            <div className='main-banner__content'>
              <p>
                <b>혁신</b>적인 기술로
                <br />
                <b>최고</b>의 서비스를 제공합니다.
              </p>
            </div>
          </MainBanner>
        </SwiperSlide>
      </Swiper>

      <Container>
        <AboutMartTong>
          <div className='about-retailx__content'>
            <div className='about-retailx__title'>
              <p data-aos='fade-right'>ABOUT</p>
              <p data-aos='fade-right' data-aos-delay='300'>
                <img src={logo} />
              </p>
            </div>

            <div className='about-retailx__description'>
              <p>
                리테일엑스는 30년간 축적된 개발 전문가의 노하우가 있는 동네
                슈퍼의 디지털화를 선도하는 e-Commerce, POS, KIOSK 개발 전문
                업체입니다.
              </p>
              <p>
                혁신 기술과 창의적인 아이디어로 최상의 서비스를 제공하는
                기업으로 우리의 목표는 항상 고객의 만족입니다.
              </p>
            </div>
          </div>

          <picture data-aos='fade-up'>
            <source media={breakpoints.mobile} srcSet={aboutMartTongMobile} />
            <img src={aboutMartTong} />
          </picture>
        </AboutMartTong>
      </Container>

      <Container $background='var(--color-grey-bg)'>
        <CoreValues>
          <ContainerTitle $disableMarginBottom data-aos='fade-right'>
            <small>Core Values</small>
            <p>핵심가치</p>
          </ContainerTitle>

          <Swiper
            loop
            speed={500}
            slidesPerView={2}
            slidesPerGroup={2}
            breakpoints={{ 1000: { slidesPerView: 4 } }}
            modules={[Pagination]}
            pagination
          >
            <SwiperSlide>
              <div
                className='core-value'
                data-aos='fade-left'
                data-aos-delay='100'
              >
                <img src={coreInnovation} />
                <p>혁신기술</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className='core-value'
                data-aos='fade-left'
                data-aos-delay='200'
              >
                <img src={coreIdea} />
                <p>창의적 아이디어</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className='core-value'
                data-aos='fade-left'
                data-aos-delay='300'
              >
                <img src={coreCustomer} />
                <p>고객중심</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className='core-value'
                data-aos='fade-left'
                data-aos-delay='400'
              >
                <img src={coreChallenge} />
                <p>도전정신</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </CoreValues>
      </Container>

      <Container>
        <ContainerTitle>
          <small data-aos='fade-up'>Our Client</small>
          <p data-aos='fade-up' data-aos-delay='100'>
            주요고객
          </p>
        </ContainerTitle>

        <OurClient>
          <div className='our-client' data-aos='zoom-in'>
            <img src={clientStore} />

            <div className='our-client__content'>
              <p className='our-client__label'>POS 가맹점</p>
              <CountUp
                className='our-client__value'
                start={0}
                end={2135}
                enableScrollSpy
                scrollSpyDelay={100}
              />
            </div>
          </div>

          <div className='our-client' data-aos='zoom-in'>
            <img src={clientCompany} />

            <div className='our-client__content'>
              <p className='our-client__label'>POS 총판 대리점</p>
              <CountUp
                className='our-client__value'
                start={0}
                end={52}
                enableScrollSpy
                scrollSpyDelay={100}
              >
                {({ countUpRef }) => (
                  <p className='our-client__value'>
                    전국 <span ref={countUpRef} />
                  </p>
                )}
              </CountUp>
            </div>
          </div>
        </OurClient>
      </Container>

      <ContactBanner type='green' />

      <Container>
        <ContainerTitle data-aos='fade-up'>
          <p>
            오직 <img src={logo} />
            에서.
          </p>
        </ContainerTitle>

        <OnlyMartTong>
          <div className='only-retailx' data-aos='zoom-in-up'>
            <picture>
              <img src={onlyPos} />
            </picture>

            <div className='only-retailx__content'>
              <p className='only-retailx__title'>독보적인 POS 시스템</p>

              <p>
                AI를 활용한 재고 예측, 판매가 및 매입가 조정 기능과 실시간 가격
                변동이 가능한 ESL 및 POP 제작 프로그램 제공, 무인 키오스크,
                모바일 키오스크와 같은 타사에서는 볼 수 없는 독보적인 POS시스템
              </p>

              <Link to='/product'></Link>
            </div>
          </div>

          <div className='only-retailx' data-aos='zoom-in-up'>
            <picture>
              <img src={onlyManless} />
            </picture>

            <div className='only-retailx__content'>
              <p className='only-retailx__title'>무인매장 원격관리 시스템</p>

              <p>
                무인매장을 스마트폰으로 간편한 제어가 가능한 솔루션 실시간
                모니터링으로 안정성과 신뢰성을 유지하고 인력 최소화로 매장
                운영비용 절감 가능
              </p>

              <Link to='/product/manless'></Link>
            </div>
          </div>

          <div className='only-retailx' data-aos='zoom-in-up'>
            <picture>
              <img src={onlyShoppingMall} />
            </picture>

            <div className='only-retailx__content'>
              <p className='only-retailx__title'>나만의 쇼핑몰 운영</p>

              <p>
                나만의 독립적인 쇼핑몰을 운영하여 브랜드 광고 및 홍보. 주문 ·
                결제 · 배송 · 홍보를 손쉽게 모바일로 운영. 인플루언서를 통한 내
                쇼핑몰 홍보.
              </p>

              <Link to='/product/app'></Link>
            </div>
          </div>

          <div className='only-retailx' data-aos='zoom-in-up'>
            <picture>
              <img src={onlyCrm} />
            </picture>

            <div className='only-retailx__content'>
              <p className='only-retailx__title'>CRM 플랫폼</p>

              <p>
                상품 이미지 촬영이 필요없는 AI 기술을 이용한 모바일전단지 제작,
                제작한 이미지를 회원에게 일괄 발송은 물론 실시간 주문까지
                관리가능
              </p>

              <Link to='/product/crm'></Link>
            </div>
          </div>
        </OnlyMartTong>
      </Container>

      <Container $background='#dce9e8'>
        <Expect>
          <ContainerTitle data-aos='fade-right'>
            <small>기대효과</small>
            <p>
              리테일엑스,
              <br />
              <strong>성공</strong>을 위한 선택
            </p>
          </ContainerTitle>

          <div
            className='expect__card'
            data-aos='fade-left'
            data-aos-delay='100'
          >
            <div className='expect__card__inner'>
              <div className='expect'>
                <p className='expect__title'>지역경제 활성화</p>

                <img src={expectLocalActive} />
              </div>

              <div className='expect'>
                <p className='expect__title'>지역경제 활성화</p>

                <p className='expect__description'>
                  백화점과 대형마트는 커녕 쿠팡과 컬리와 같은 e커머스의 새벽배송
                  서비스를 누리기도 쉽지 않은{' '}
                  <b>지방에선 슈퍼마켓이 중요한 역할</b>을 합니다. 리테일엑스와
                  ‘동네 슈퍼’의 혁신을 이끌면 <b>지역경제 활성화</b>를 기대 할
                  수 있습니다.
                </p>

                <img src={expectLocal} />
              </div>
            </div>
          </div>

          <div
            className='expect__card'
            data-aos='fade-left'
            data-aos-delay='200'
          >
            <div className='expect__card__inner'>
              <div className='expect'>
                <p className='expect__title'>경쟁력 강화</p>

                <img src={expectCompetitionActive} />
              </div>

              <div className='expect'>
                <p className='expect__title'>경쟁력 강화</p>

                <p className='expect__description'>
                  편의점과 대형마트의 사이에서 <b>경쟁력</b>을 가지려면 동네
                  슈퍼 및 마트가 <b>정보기술(IT)을 입혀 진화</b>를 해야 합니다.
                  ‘미지의 영역’으로 분류되는 동네 슈퍼가 혁신하기 시작하면
                  유통업계에 새로운 변화를 기대할 수 있습니다.
                </p>

                <img src={expectCompetition} />
              </div>
            </div>
          </div>

          <div
            className='expect__card'
            data-aos='fade-left'
            data-aos-delay='100'
          >
            <div className='expect__card__inner'>
              <div className='expect'>
                <p className='expect__title'>프로그램 무료 제공</p>

                <img src={expectSoftwareActive} />
              </div>

              <div className='expect'>
                <p className='expect__title'>프로그램 무료 제공</p>

                <p className='expect__description'>
                  O2O플랫폼 지원 사업을 통해 차세대{' '}
                  <b>POS 및 POP제작 프로그램을 무료 제공</b>함으로써 단시일에{' '}
                  <b>2,000여 가맹점의 확보</b>가 가능하고 가맹점에게는 경쟁력
                  확보가 가능합니다.
                </p>

                <img src={expectSoftware} />
              </div>
            </div>
          </div>

          <div
            className='expect__card'
            data-aos='fade-left'
            data-aos-delay='200'
          >
            <div className='expect__card__inner'>
              <div className='expect'>
                <p className='expect__title'>종합 솔루션 제공</p>

                <img src={expectSolutionActive} />
              </div>

              <div className='expect'>
                <p className='expect__title'>종합 솔루션 제공</p>

                <p className='expect__description'>
                  POS 프로그램, 무인 키오스크, 위치 기반의 매장 만의 독립적인
                  쇼핑몰, 모바일전단지 쇼핑몰 제작 플랫폼 등 단일
                  고객층(소상공인)을 대상으로 하는 <b>종합 솔루션 공급</b>이
                  가능합니다.
                </p>

                <img src={expectSolution} />
              </div>
            </div>
          </div>

          <div
            className='expect__card'
            data-aos='fade-left'
            data-aos-delay='300'
          >
            <div className='expect__card__inner'>
              <div className='expect'>
                <p className='expect__title'>고용 창출</p>

                <img src={expectHireActive} />
              </div>

              <div className='expect'>
                <p className='expect__title'>고용 창출</p>

                <p className='expect__description'>
                  소상공인은 경제 활동의 주요 고용 창출자 입니다. 이들의
                  활성화는 일자리를 창출하고 실질적인 <b>경제 활동을 촉진</b>
                  하는 데 도움이 됩니다.
                </p>

                <img src={expectHire} />
              </div>
            </div>
          </div>
        </Expect>
      </Container>
    </>
  );
};

export default Main;
