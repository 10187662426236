import styled from 'styled-components';

export const PrivacyContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);

  .privacy__content {
    white-space: pre-wrap;
  }
`;
